import React from 'react';
// import { post,get } from "../API/apiHelper";
// import { listAirlinePrices } from "../API/endpoints";

// export async function   countries () {
//   try {
//     const res = await post(listAirlinePrices, true);
//     const response = await res.json();
//     return response.data;
// } catch (error) {
//     console.log(error)
// }
// }
const countries = [
    { code: "AF", currency: "AFN", name: "Afghanistan" },
    { code: "AL", currency: "ALL", name: "Albania" },
    { code: "DZ", currency: "DZD", name: "Algeria" },
    { code: "AS", currency: "USD", name: "American Samoa" },
    { code: "AD", currency: "EUR", name: "Andorra" },
    { code: "AO", currency: "AOA", name: "Angola" },
    { code: "AG", currency: "XCD", name: "Antigua and Barbuda" },
    { code: "AR", currency: "ARS", name: "Argentina" },
    { code: "AM", currency: "AMD", name: "Armenia" },
    { code: "AU", currency: "AUD", name: "Australia" },
    { code: "AT", currency: "EUR", name: "Austria" },
    { code: "AZ", currency: "AZN", name: "Azerbaijan" },
    { code: "BS", currency: "BSD", name: "Bahamas" },
    { code: "BH", currency: "BHD", name: "Bahrain" },
    { code: "BD", currency: "BDT", name: "Bangladesh" },
    { code: "BB", currency: "BBD", name: "Barbados" },
    { code: "BY", currency: "BYN", name: "Belarus" },
    { code: "BE", currency: "EUR", name: "Belgium" },
    { code: "BZ", currency: "BZD", name: "Belize" },
    { code: "BJ", currency: "XOF", name: "Benin" },
    { code: "BT", currency: "BTN", name: "Bhutan" },
    { code: "BO", currency: "BOB", name: "Bolivia" },
    { code: "BA", currency: "BAM", name: "Bosnia and Herzegovina" },
    { code: "BW", currency: "BWP", name: "Botswana" },
    { code: "BR", currency: "BRL", name: "Brazil" },
    { code: "BN", currency: "BND", name: "Brunei Darussalam" },
    { code: "BG", currency: "BGN", name: "Bulgaria" },
    { code: "BF", currency: "XOF", name: "Burkina Faso" },
    { code: "BI", currency: "BIF", name: "Burundi" },
    { code: "KH", currency: "KHR", name: "Cambodia" },
    { code: "CM", currency: "XAF", name: "Cameroon" },
    { code: "CA", currency: "CAD", name: "Canada" },
    { code: "CV", currency: "CVE", name: "Cape Verde" },
    { code: "CF", currency: "XAF", name: "Central African Republic" },
    { code: "TD", currency: "XAF", name: "Chad" },
    { code: "CL", currency: "CLP", name: "Chile" },
    { code: "CN", currency: "CNY", name: "China" },
    { code: "CO", currency: "COP", name: "Colombia" },
    { code: "KM", currency: "KMF", name: "Comoros" },
    { code: "CG", currency: "XAF", name: "Congo" },
    { code: "CR", currency: "CRC", name: "Costa Rica" },
    { code: "HR", currency: "HRK", name: "Croatia" },
    { code: "CU", currency: "CUP", name: "Cuba" },
    { code: "CY", currency: "EUR", name: "Cyprus" },
    { code: "CZ", currency: "CZK", name: "Czech Republic" },
    { code: "DK", currency: "DKK", name: "Denmark" },
    { code: "DJ", currency: "DJF", name: "Djibouti" },
    { code: "DM", currency: "XCD", name: "Dominica" },
    { code: "DO", currency: "DOP", name: "Dominican Republic" },
    { code: "EC", currency: "USD", name: "Ecuador" },
    { code: "EG", currency: "EGP", name: "Egypt" },
    { code: "SV", currency: "USD", name: "El Salvador" },
    { code: "GQ", currency: "XAF", name: "Equatorial Guinea" },
    { code: "ER", currency: "ERN", name: "Eritrea" },
    { code: "EE", currency: "EUR", name: "Estonia" },
    { code: "ET", currency: "ETB", name: "Ethiopia" },
    { code: "FJ", currency: "FJD", name: "Fiji" },
    { code: "FI", currency: "EUR", name: "Finland" },
    { code: "FR", currency: "EUR", name: "France" },
    { code: "GA", currency: "XAF", name: "Gabon" },
    { code: "GM", currency: "GMD", name: "Gambia" },
    { code: "GE", currency: "GEL", name: "Georgia" },
    { code: "DE", currency: "EUR", name: "Germany" },
    { code: "GH", currency: "GHS", name: "Ghana" },
    { code: "GR", currency: "EUR", name: "Greece" },
    { code: "GD", currency: "XCD", name: "Grenada" },
    { code: "GT", currency: "GTQ", name: "Guatemala" },
    { code: "GN", currency: "GNF", name: "Guinea" },
    { code: "GW", currency: "XOF", name: "Guinea-Bissau" },
    { code: "GY", currency: "GYD", name: "Guyana" },
    { code: "HT", currency: "HTG", name: "Haiti" },
    { code: "HN", currency: "HNL", name: "Honduras" },
    { code: "HU", currency: "HUF", name: "Hungary" },
    { code: "IS", currency: "ISK", name: "Iceland" },
    { code: "IN", currency: "INR", name: "India" },
    { code: "ID", currency: "IDR", name: "Indonesia" },
    { code: "IR", currency: "IRR", name: "Iran" },
    { code: "IQ", currency: "IQD", name: "Iraq" },
    { code: "IE", currency: "EUR", name: "Ireland" },
    { code: "IL", currency: "ILS", name: "Israel" },
    { code: "IT", currency: "EUR", name: "Italy" },
    { code: "JM", currency: "JMD", name: "Jamaica" },
    { code: "JP", currency: "JPY", name: "Japan" },
    { code: "JO", currency: "JOD", name: "Jordan" },
    { code: "KZ", currency: "KZT", name: "Kazakhstan" },
    { code: "KE", currency: "KES", name: "Kenya" },
    { code: "KI", currency: "AUD", name: "Kiribati" },
    { code: "KP", currency: "KPW", name: "North Korea" },
    { code: "KR", currency: "KRW", name: "South Korea" },
    { code: "KW", currency: "KWD", name: "Kuwait" },
    { code: "KG", currency: "KGS", name: "Kyrgyzstan" },
    { code: "LA", currency: "LAK", name: "Laos" },
    { code: "LV", currency: "EUR", name: "Latvia" },
    { code: "LB", currency: "LBP", name: "Lebanon" },
    { code: "LS", currency: "LSL", name: "Lesotho" },
    { code: "LR", currency: "LRD", name: "Liberia" },
    { code: "LY", currency: "LYD", name: "Libya" },
    { code: "LI", currency: "CHF", name: "Liechtenstein" },
    { code: "LT", currency: "EUR", name: "Lithuania" },
    { code: "LU", currency: "EUR", name: "Luxembourg" },
    { code: "MG", currency: "MGA", name: "Madagascar" },
    { code: "MW", currency: "MWK", name: "Malawi" },
    { code: "MY", currency: "MYR", name: "Malaysia" },
    { code: "MV", currency: "MVR", name: "Maldives" },
    { code: "ML", currency: "XOF", name: "Mali" },
    { code: "MT", currency: "EUR", name: "Malta" },
    { code: "MH", currency: "USD", name: "Marshall Islands" },
    { code: "MR", currency: "MRU", name: "Mauritania" },
    { code: "MU", currency: "MUR", name: "Mauritius" },
    { code: "MX", currency: "MXN", name: "Mexico" },
    { code: "FM", currency: "USD", name: "Micronesia" },
    { code: "MD", currency: "MDL", name: "Moldova" },
    { code: "MC", currency: "EUR", name: "Monaco" },
    { code: "MN", currency: "MNT", name: "Mongolia" },
    { code: "ME", currency: "EUR", name: "Montenegro" },
    { code: "MA", currency: "MAD", name: "Morocco" },
    { code: "MZ", currency: "MZN", name: "Mozambique" },
    { code: "MM", currency: "MMK", name: "Myanmar" },
    { code: "NA", currency: "NAD", name: "Namibia" },
    { code: "NR", currency: "AUD", name: "Nauru" },
    { code: "NP", currency: "NPR", name: "Nepal" },
    { code: "NL", currency: "EUR", name: "Netherlands" },
    { code: "NZ", currency: "NZD", name: "New Zealand" },
    { code: "NI", currency: "NIO", name: "Nicaragua" },
    { code: "NE", currency: "XOF", name: "Niger" },
    { code: "NG", currency: "NGN", name: "Nigeria" },
    { code: "NO", currency: "NOK", name: "Norway" },
    { code: "OM", currency: "OMR", name: "Oman" },
    { code: "PK", currency: "PKR", name: "Pakistan" },
    { code: "PW", currency: "USD", name: "Palau" },
    { code: "PA", currency: "PAB", name: "Panama" },
    { code: "PG", currency: "PGK", name: "Papua New Guinea" },
    { code: "PY", currency: "PYG", name: "Paraguay" },
    { code: "PE", currency: "PEN", name: "Peru" },
    { code: "PH", currency: "PHP", name: "Philippines" },
    { code: "PL", currency: "PLN", name: "Poland" },
    { code: "PT", currency: "EUR", name: "Portugal" },
    { code: "QA", currency: "QAR", name: "Qatar" },
    { code: "RO", currency: "RON", name: "Romania" },
    { code: "RU", currency: "RUB", name: "Russia" },
    { code: "RW", currency: "RWF", name: "Rwanda" },
    { code: "KN", currency: "XCD", name: "Saint Kitts and Nevis" },
    { code: "LC", currency: "XCD", name: "Saint Lucia" },
    { code: "VC", currency: "XCD", name: "Saint Vincent and the Grenadines" },
    { code: "WS", currency: "WST", name: "Samoa" },
    { code: "SM", currency: "EUR", name: "San Marino" },
    { code: "ST", currency: "STN", name: "Sao Tome and Principe" },
    { code: "SA", currency: "SAR", name: "Saudi Arabia" },
    { code: "SN", currency: "XOF", name: "Senegal" },
    { code: "RS", currency: "RSD", name: "Serbia" },
    { code: "SC", currency: "SCR", name: "Seychelles" },
    { code: "SL", currency: "SLL", name: "Sierra Leone" },
    { code: "SG", currency: "SGD", name: "Singapore" },
    { code: "SK", currency: "EUR", name: "Slovakia" },
    { code: "SI", currency: "EUR", name: "Slovenia" },
    { code: "SB", currency: "SBD", name: "Solomon Islands" },
    { code: "SO", currency: "SOS", name: "Somalia" },
    { code: "ZA", currency: "ZAR", name: "South Africa" },
    { code: "SS", currency: "SSP", name: "South Sudan" },
    { code: "ES", currency: "EUR", name: "Spain" },
    { code: "LK", currency: "LKR", name: "Sri Lanka" },
    { code: "SD", currency: "SDG", name: "Sudan" },
    { code: "SR", currency: "SRD", name: "Suriname" },
    { code: "SE", currency: "SEK", name: "Sweden" },
    { code: "CH", currency: "CHF", name: "Switzerland" },
    { code: "SY", currency: "SYP", name: "Syria" },
    { code: "TW", currency: "TWD", name: "Taiwan" },
    { code: "TJ", currency: "TJS", name: "Tajikistan" },
    { code: "TZ", currency: "TZS", name: "Tanzania" },
    { code: "TH", currency: "THB", name: "Thailand" },
    { code: "TL", currency: "USD", name: "Timor-Leste" },
    { code: "TG", currency: "XOF", name: "Togo" },
    { code: "TO", currency: "TOP", name: "Tonga" },
    { code: "TT", currency: "TTD", name: "Trinidad and Tobago" },
    { code: "TN", currency: "TND", name: "Tunisia" },
    { code: "TR", currency: "TRY", name: "Turkey" },
    { code: "TM", currency: "TMT", name: "Turkmenistan" },
    { code: "TV", currency: "AUD", name: "Tuvalu" },
    { code: "UG", currency: "UGX", name: "Uganda" },
    { code: "UA", currency: "UAH", name: "Ukraine" },
    { code: "AE", currency: "AED", name: "United Arab Emirates" },
    { code: "GB", currency: "GBP", name: "United Kingdom" },
    { code: "US", currency: "USD", name: "United States" },
    { code: "UY", currency: "UYU", name: "Uruguay" },
    { code: "UZ", currency: "UZS", name: "Uzbekistan" },
    { code: "VU", currency: "VUV", name: "Vanuatu" },
    { code: "VE", currency: "VES", name: "Venezuela" },
    { code: "VN", currency: "VND", name: "Vietnam" },
    { code: "YE", currency: "YER", name: "Yemen" },
    { code: "ZM", currency: "ZMW", name: "Zambia" },
    { code: "ZW", currency: "ZWL", name: "Zimbabwe" }
  ];
export default countries;
