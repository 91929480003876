import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Progress from "../../../component/Loading";
import Ticket_Details from '../../../widget/ticket_details';

const Ticket = () => {

    const { id } = useParams();
    const reference_id = atob(id);

    return (
        <div className="border mb-4 card">

            <Ticket_Details reference_id={reference_id} />

        </div>
    );
};

export default Ticket;
