import React, { useState, useEffect, useRef } from 'react';

import '../flight-booking-main/flight-booking-main.css'
import route_plane from "../../../assets/images/icon/route-plan.png"
import { post } from "../../../API/airline";
import { third_party, AIR_2_URL, AIR_FARERULE } from "../../../API/endpoints";
import { useLocation } from 'react-router-dom';
import Progress from "../../../component/Loading";
import { Modal } from 'react-bootstrap';

const FlightBookingDetails = ({ data, onUpdate }) => {
    const location = useLocation();
    // const { FlightData, Search_Key, fareid, charges, adult, faredetails } = data || {};

    const FlightData = data[0].flight;
    const Search_Key = data[0].Search_Key;
    const fareid = data[0].fareid;
    const charges = data[0].charges;
    const adult = data[0].adultcount;
    const faredetails = data[0].item;

    const [Data, setData] = useState(FlightData);
    const [loading, setLoding] = useState(null);
    const [Frule, setcode] = useState([]);

    useEffect(() => {
        const fetchrules = async () => {
            try {
                setLoding(true);
                const payload = {
                    "Auth_Header": {
                        "UserId": "viviantravelsuat",
                        "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                        "IP_Address": "12333333",
                        "Request_Id": "5500887959052",
                        "IMEI_Number": "9536615000"
                    },
                    "Search_Key": Search_Key,
                    "Flight_Key": Data.Flight_Key,
                    "Fare_Id": fareid
                }
                const api_url = await AIR_2_URL() + AIR_FARERULE;
                const response = await post(third_party, JSON.stringify(payload), api_url);
                const resp = await response.json();
                if (resp.data.FareRules.length > 0) {
                    setcode(resp.data.FareRules[0].FareRuleDesc);
                } else {
                    setcode("<p>No fare rules found try another </p>")
                }
                setLoding(false);
            } catch (error) {
                setLoding(false);
                console.error('Failed to fetch data:', error);
            }
        };
        fetchrules();
    }, []);



    const formatDate = (dateString) => {
        const date = new Date(dateString).toLocaleDateString([], {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        return date;
    };

    const formattime = (timeString) => {
        const time = new Date(timeString).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        return time;
    };

    const Add_amount = () => {

    };

    const sum = data.reduce((total, item) => {
        return  total + (item.item.FareDetails[0].Total_Amount + Number(item.charges) || 0); // Add the amount if it exists, otherwise add 0
    }, 0);


    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    const truncatedContent = Frule.length > 1000 ? Frule.slice(0, 1000) + "..." : Frule;
    const AdultAmount = (sum) * (data[0].adultcount.adult);
    const childAmount = (sum) * (data[0].adultcount.child);
    const infantAmount = (((sum) * 10) / (100));
    const finalinfantAmount = (((sum) * 10) / (100) * Number(data[0].adultcount.infant));
    const bookingamount = Number(AdultAmount) + Number(childAmount) + Number(finalinfantAmount);



    return (
        <div className="col-xl-4 mb-lg-0 mb-32">
            {/* <div className="flight-booking-detail light-shadow mb-32">
                <div className="flight-title">
                    <h4 className="color-black">Your Booking Detail</h4>
                </div>
                <div className="box bg-white p-24">
                    {data.map((Details, index) => (
                        <>
                            <div className="flight-detail mb-32">
                                <div className="flight-departure">
                                    <h5 className="color-black">{formattime(Details.flight.Segments[0].Departure_DateTime)}</h5>
                                    <h5 className="dark-gray text-end">{Details.flight.Segments[0].Destination}</h5>
                                </div>
                                <div className="d-inline-flex align-items-center gap-8">
                                    <span>From</span>
                                    <div className="from-to text-center">
                                        <h5 className="dark-gray">{Details.flight.Segments[0].Duration}</h5>
                                        <img className='route-plan' src={route_plane} alt="Route Plan" />
                                        <h6 className="color-black">{Details.flight.Segments[0].Stop_Over}</h6>
                                    </div>
                                    <span>To</span>
                                </div>
                                <div className="flight-departure">
                                    <h5 className="color-black">{formattime(Details.flight.Segments[0].Arrival_DateTime)}</h5>
                                    <h5 className="dark-gray">{Details.flight.Segments[0].Origin}</h5>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-20">
                                <div className="flight-departure">
                                    <h6 className="dark-gray">Departure</h6>
                                    <h5 className="color-black">{formatDate(Details.flight.Segments[0].Departure_DateTime)}</h5>
                                </div>
                                <div className="vr-line"></div>
                                <div className="flight-departure">
                                    <h6 className="dark-gray">Arrival</h6>
                                    <h5 className="color-black">{formatDate(Details.flight.Segments[0].Arrival_DateTime)}</h5>
                                </div>
                            </div>
                            <hr className="bg-medium-gray mb-20" />
                            <div className="text">
                                <h6 className="color-medium-gray">Tpm Line</h6>
                                <h6 className="color-medium-gray">Operated by {Details.flight.Segments[0].Airline_Name}</h6>
                                <h6 className="color-medium-gray">Flight {Details.flight.Segments[0].Flight_Number} | Aircraft {Details.flight.Segments[0].Aircraft_Type}</h6>
                                <h6 className="color-medium-gray">Adult(s): {Details.flight.Segments[0].Leg_Index}</h6>
                            </div><br />
                        </>
                    ))
                    }
                </div>
            </div> */}

            <div className="flight-booking-detail light-shadow mb-32">
                <div className="flight-title">
                    <div className="row d-flex align-items-center justify-content-between">
                        <h4 className="color-black col-8 p-0">Payment Details</h4>
                        <h6 className="color-black col-4 p-0" style={{ cursor: 'pointer', textAlign: 'right' }} onClick={() => onUpdate({})}>Change Fare</h6>
                    </div>
                </div>
                <div className="box bg-white p-24">

                    {data.map((Details, index) => (
                        <>
                            <div className="flight-detail mb-32">
                                <div className="flight-departure">
                                    <h5 className="color-black">{formattime(Details.flight.Segments[0].Departure_DateTime)}</h5>
                                    <h5 className="dark-gray text-end">{Details.flight.Segments[0].Destination}</h5>
                                </div>
                                <div className="d-inline-flex align-items-center gap-8">
                                    <span>From</span>
                                    <div className="from-to text-center">
                                        <h5 className="dark-gray">{Details.flight.Segments[0].Duration}</h5>
                                        <img className='route-plan' src={route_plane} alt="Route Plan" />
                                        <h6 className="color-black">{Details.flight.Segments[0].Stop_Over}</h6>
                                    </div>
                                    <span>To</span>
                                </div>
                                <div className="flight-departure">
                                    <h5 className="color-black">{formattime(Details.flight.Segments[0].Arrival_DateTime)}</h5>
                                    <h5 className="dark-gray">{Details.flight.Segments[0].Origin}</h5>
                                </div>
                            </div>
                            <div className="d-flex justify-content-around mb-20">
                                <div className="flight-departure">
                                    <h6 className="dark-gray">Departure</h6>
                                    <h5 className="color-black">{formatDate(Details.flight.Segments[0].Departure_DateTime)}</h5>
                                </div>
                                <div className="vr-line"></div>
                                <div className="flight-departure">
                                    <h6 className="dark-gray">Arrival</h6>
                                    <h5 className="color-black">{formatDate(Details.flight.Segments[0].Arrival_DateTime)}</h5>
                                </div>
                            </div>


                            <div className="fare-details mb-2">
                                <h6 className="text-secondary fw-bold">Fare Details</h6>
                                <div className="ps-2">
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0 text-muted">Basic Amount:</p>
                                        <p className="mb-0">₹{Details.item.FareDetails[0].Basic_Amount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="taxes mb-2">
                                <h6 className="text-secondary fw-bold">Airport Taxes</h6>
                                <div className="ps-2">
                                    {Details.item.FareDetails[0].AirportTaxes.map((tax, idx) => (
                                        <div key={idx} className="d-flex justify-content-between">
                                            <p className="mb-0 text-muted">{tax.Tax_Desc}:</p>
                                            <p className="mb-0">₹{tax.Tax_Amount}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="fare-class mb-2">
                                <h6 className="text-secondary fw-bold">Fare Class</h6>
                                <div className="ps-2">
                                    {Details.item.FareDetails[0].FareClasses.map((fareClass, idx) => (
                                        <div key={idx} className="d-flex justify-content-between">
                                            <p className="mb-0 text-muted">{fareClass.Class_Desc} ({fareClass.Class_Code}):</p>
                                            <p className="mb-0">{fareClass.FareBasis}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="additional-charges mb-2">
                                <h6 className="text-secondary fw-bold">Additional Charges</h6>
                                <div className="ps-2">
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0 text-muted">GST:</p>
                                        <p className="mb-0">₹{Details.item.FareDetails[0].GST || '0'}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0 text-muted">Service Fee:</p>
                                        <p className="mb-0">₹{Details.item.FareDetails[0].Service_Fee_Amount || '0'}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0 text-muted">Promo Discount:</p>
                                        <p className="mb-0">₹{Details.item.FareDetails[0].Promo_Discount || '0'}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="additional-charges mb-2">
                                <h6 className="text-secondary fw-bold">Sub Total</h6>
                                <div className="ps-2">
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-0 text-muted">Amount:</p>
                                        <p className="mb-0">₹{Number(Details.item.FareDetails[0].Total_Amount) + Number(Details.charges) || '0'}</p>
                                    </div>
                                </div>
                            </div>
                            <hr /><br />
                        </>
                    ))
                    }


                    <h6 className="text-secondary fw-bold mb-1">Fare Details</h6>
                            <div className="ps-2">
                                <div className="d-flex justify-content-between mb-1">
                                    <p className="mb-0 text-muted">Adult(s) Amount : </p>
                                    <p className="mb-0">({data[0].adultcount.adult} x  { sum }) = ₹{AdultAmount}</p>
                                </div>
                                {Number(childAmount) <= 0 ? '' :
                                    <div className="d-flex justify-content-between mb-1">
                                        <p className="mb-0 text-muted">Child(s) Amount :</p>
                                        <p className="mb-0">({data[0].adultcount.child} x  {sum}) = ₹{childAmount}</p>

                                    </div>
                                }
                                {Number(finalinfantAmount) <= 0 ? '' :
                                    <div className="d-flex justify-content-between mb-1">
                                        <p className="mb-0 text-muted">Infant(s) Amount :</p>
                                        <p className="mb-0">  ({data[0].adultcount.infant} x  {infantAmount}) = ₹{finalinfantAmount}</p>
                                    </div>
                                }
                                <div className="total-amount p-2 bg-secondary text-dark rounded mt-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 className="fw-bold mb-0">Total Amount:</h5>
                                        <h5 className="fw-bold mb-0">
                                            ₹{bookingamount}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                </div>

            </div>

            <div className="flight-booking-detail light-shadow">
                <div className="flight-title">
                    <h4 className="color-black">Fares Rules</h4>
                </div>
                <div className="box bg-white p-24">
                    {loading ? (
                        <Progress />
                    ) : (
                        <>
                            {/* Display truncated content */}
                            <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />

                            {/* Show "View More" button if content is truncated */}
                            {Frule.length > 500 && (
                                <button className="btn btn-primary mt-3 btn-sm" onClick={toggleModal}>
                                    View More
                                </button>
                            )}
                        </>
                    )}
                </div>

                {/* Modal to display full content */}
                <Modal show={showModal} onHide={toggleModal} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Fare Rules</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: Frule }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={toggleModal}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default FlightBookingDetails;
