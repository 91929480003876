import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';
import { post, get } from "../../../../API/apiHelper";
import { post as HelperPost } from "../../../../API/apiHelper";
import { razarpaypayment } from "../../../../API/utils";

import { apply_visa, wallet_add, siteconfig, users_profile, maincountry_list } from "../../../../API/endpoints";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCalendarAlt } from "react-icons/fa";
import Progress from "../../../../component/Loading";
import { CiWallet } from "react-icons/ci";
import { SiRazorpay } from "react-icons/si";
import logo from "../../../../assets/images/logo.png"

import country from '../../../../widget/country';
import Select from 'react-select';


const dropzoneStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "rgba(77, 115, 252, 0.1)",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    cursor: "pointer",
};

const activeDropzoneStyle = {
    borderColor: "#00adb5",
};

const DropzoneText = {
    margin: "0",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "20px",
};

const ImagePreview = {
    display: "flex",
    maxWidth: "100%",
    maxHeight: "150px",
    margin: "auto",
    borderRadius: "2px",
};

const FileName = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontSize: "14px",
    marginTop: "8px",
    position: "absolute",
    bottom: "-26px",
    right: "50%",
    transform: "translate(50%)",
};




function TabComponent({ visaDetails }) {

    const navigate = useNavigate();
    const [visaDetail, setVisaDetails] = useState(visaDetails || {});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encodedformData = queryParams.get('other');
    const formData = atob(encodedformData);
    const jsonObject = JSON.parse(formData);


    const travelDate = new Date(jsonObject.travelDate);
    const returnDate = new Date(jsonObject.returnDate);
    const formattedDate = travelDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
    const formattedreturnDate = returnDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });


    const [isConfirmed, setIsConfirmed] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsConfirmed(e.target.checked);
    };

    useEffect(() => {
        if (visaDetails) {
            setVisaDetails(visaDetails);
        }

    }, [visaDetails]);
    const [passportNumber, setPassportNumber] = useState('');
    const [penNumber, setPenNumber] = useState('');
    const [photoNumber, setPhotoNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nationality, setNationality] = useState('');
    const [sex, setSex] = useState('');
    const [birthday, setBirthday] = useState('');
    const [addi, setaddional] = useState('');
    const [files, setFiles] = useState('');
    const [setting, setSettings] = useState(null);
    const [Passenger, setPassenger] = useState(false);
    const [Isinsurense, setIsinsurense] = useState(false);

    const [formlist, setFormlist] = useState([
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: true,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
        {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        },
    ]);

    const updateField = (index, key, value) => {
        const newFormList = [...formlist];
        // console.log(`hi yogesh here your data ${index}  ${key}  ${value}`)
        if (key == 'nationality') {
            if (value == 'United Arab Emirates') {
                setPassenger(true);
                setIsinsurense(true);
            } else {
                setPassenger(false);
                setIsinsurense(false);
            }
            newFormList[index] = value == "United Arab Emirates" ? {
                ...newFormList[index],
                [key]: value,
                ['Passenger_type']: ""
            } : {
                ...newFormList[index],
                [key]: value,
                ['Passenger_type']: "Adult"
            }
            setFormlist(newFormList);

        } else {

            newFormList[index] = {
                ...newFormList[index],
                [key]: value,
            };
            setFormlist(newFormList);

        }

    };

    const addNewTraveler = () => {
        const newFormList = [...formlist];
        const index = newFormList.filter((item) => item.show).length;
        if (index == 10) {
            toast.success("List");
        } else {
            newFormList[index] = {
                ...newFormList[index],
                ["show"]: true,
            };
            setFormlist(newFormList);
        }
    };
    const removeitem = (index) => {
        const newFormList = [...formlist];

        newFormList[index] = {
            passportNumber: "",
            penNumber: "",
            firstName: "",
            lastName: "",
            sex: "",
            birthday: "",
            addi: "",
            files: "",
            front_passport_img: "",
            back_passport_img: "",
            traveler_photo: "",
            pen_card_photo: "",
            Passenger_type: "",
            motherName: "",
            fatherName: "",
            placeOfBirth: "",
            spouseName: "",
            travelDate: "",
            entryPoint: "",
            exitPoint: "",
            show: false,
        };
        setFormlist(newFormList);
    };


    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession != null) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
        const fetchSettings = async () => {
            try {
                const res = await get(siteconfig, true);
                const response = await res.json();
                setSettings(response.data);
            } catch (error) {
                console.log(error)
            }
        };
        fetchSettings();
    }, []);

    const [uData, setUData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(users_profile, true);
                if (!response.ok) {
                    const errorMsg = await response.text();
                    throw new Error(`Error ${response.status}: ${errorMsg}`);
                }
                const data = await response.json();
                setUData(data.data)
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        fetchUserData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formlist);

        if (setting != null) {
            let a_amount = '0';
            if (uData.type == 2) {
                a_amount = uData.agents ? uData.agents.visa_booking_c : ''
            }

            const total_amount =
                (((Number(visaDetail.amount) + Number(setting != null ? setting.visa_agency_charge : "0") + Number((Isinsurense && isConfirmed) ? 49 : 0)) *
                    formlist.filter((item) => item.show && item.Passenger_type === 'Adult').length) +
                    ((500 +
                        Number(setting != null ? setting.visa_agency_charge : "0")) *
                        formlist.filter((item) => item.show && item.Passenger_type === 'Child').length
                    ));
            const commission = (Number(a_amount) * formlist.filter((item) => item.show).length);

            const finalamount = Number(total_amount) - Number(commission);

            if (paymentMethod == 'razorpay') {
                razarpaypayment('11', finalamount, 'Visa Applied', '', async (response) => {
                    if (response.razorpay_payment_id && response.razorpay_payment_id != null) {

                        formlist.filter((item) => item.show).forEach((traveler) => {
                            const formData = {
                                'user_id': userData.id,
                                'visa_id': visaDetails.id,
                                'visa_type': 'individual',
                                'internal_ID': '1234',
                                'group_name': 'no group',
                                'passport_no': traveler.passportNumber,
                                'first_name': traveler.firstName,
                                'last_name': traveler.lastName,
                                'nationality': traveler.nationality,
                                'sex': traveler.sex,
                                'dob': traveler.birthday,
                                'pen_card_no': traveler.panNumber,
                                'additional_question': traveler.addi,
                                'photodaysNumber': traveler.photoNumber,
                                'front_passport_img': traveler.front_passport_img,
                                'back_passport_img': traveler.back_passport_img,
                                'traveler_photo': traveler.traveler_photo,
                                'pen_card_photo': traveler.pen_card_photo,

                                'motherName': traveler.motherName,
                                'fatherName': traveler.fatherName,
                                'placeOfBirth': traveler.placeOfBirth,
                                'spouseName': traveler.spouseName,
                                'travelDate': traveler.travelDate,
                                'entryPoint': traveler.entryPoint,
                                'exitPoint': traveler.exitPoint,
                                'is_insurance': (Isinsurense && isConfirmed) ? 'Yes' : 'No',
                            };
                            apply_visaafterpayment(formData);
                        });
                    }
                })
            } else {
                if (userData.wallet >= finalamount) {
                    // proceed_booking('Wallet', total_amount, RefNo, '')
                    const formDatawallet = {
                        user_id: userData.id,
                        order_id: "",
                        transaction_type: 'Visa Apply',
                        amount: finalamount,
                        payment_getway: "wallet",
                        details: 'Visa Apply',
                        type: '2',
                        status: 'Success'
                    };
                    await HelperPost(wallet_add, formDatawallet, true);
                    // apply_visaafterpayment(formData)

                    formlist.filter((item) => item.show).forEach((traveler) => {
                        const formData = {
                            'user_id': userData.id,
                            'visa_id': visaDetails.id,
                            'visa_type': 'individual',
                            'internal_ID': '1234',
                            'group_name': 'no group',
                            'passport_no': traveler.passportNumber,
                            'first_name': traveler.firstName,
                            'last_name': traveler.lastName,
                            'nationality': traveler.nationality,
                            'sex': traveler.sex,
                            'dob': traveler.birthday,
                            'pen_card_no': traveler.panNumber,
                            'additional_question': traveler.addi,
                            'photodaysNumber': traveler.photoNumber,
                            'front_passport_img': traveler.front_passport_img,
                            'back_passport_img': traveler.back_passport_img,
                            'traveler_photo': traveler.traveler_photo,
                            'pen_card_photo': traveler.pen_card_photo,
                        };
                        apply_visaafterpayment(formData);
                    });
                } else {
                    toast.error('Your Wallet Balance is low');
                }
            }
        }
    };


    async function apply_visaafterpayment(formData) {
        try {
            const response = await post(apply_visa, formData, true);
            const data = await response.json();
            if (data.status == false) {
                toast.error(data.message);
            } else {
                toast.success(data.message);
                setTimeout(() => {
                    navigate("/visa-status");
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const onDrop = useCallback((acceptedFiles, key) => {
        setFiles((prevState) => ({
            ...prevState,
            [key]: acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        }));
    }, []);

    const CreateDropzone = (index, key, key2) => {
        return useDropzone({
            accept: {
                "image/jpeg": [],
                "image/png": [],
                "image/jpg": [],
                "application/pdf": [],
            },
            maxSize: 1024 * 1024 * 5, // 5MB
            maxFiles: 3,
            onDrop: (acceptedFiles) => {
                updateField(index, key, acceptedFiles[0]);
                onDrop(acceptedFiles, key2);
            },
        });
    };
    const toFrontPassport = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'application/pdf': [],
        },
        // maxSize: 1024 * 1024 * 5, // 5MB
        maxFiles: 3,
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'frontPassport')
    });
    const toBackPassport = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'application/pdf': [],
        },
        // maxSize: 1024 * 1024 * 5, // 5MB
        maxFiles: 3,
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'backPassport')
    });
    const toTravelersPhoto = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'application/pdf': [],
        },
        // maxSize: 1024 * 1024 * 5, // 5MB
        maxFiles: 3,
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'travelersPhoto')
    });
    const toTravelersPanCard = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'application/pdf': [],
        },
        // maxSize: 1024 * 1024 * 5, // 5MB
        maxFiles: 3,
        onDrop: (acceptedFiles) => onDrop(acceptedFiles, 'travelersPAN')
    });

    const renderDropzone = (label, dropzoneProps, fieldKey, options = {}) => {
        const { getRootProps, getInputProps, isDragActive, acceptedFiles } = dropzoneProps;

        // Check if there are any uploaded files
        const hasUploadedFiles = acceptedFiles && acceptedFiles.length > 0;

        return (
            <div className="dz-clickable mb-3" key={fieldKey}>
                <label className="form-label" htmlFor={fieldKey}>
                    {label} <span className="text-danger">*</span>
                </label>
                <div
                    {...getRootProps()}
                    style={{
                        border: '2px dashed rgba(0, 123, 255, 0.3)',
                        borderRadius: '12px',
                        padding: '30px',
                        background: 'linear-gradient(135deg, #f8f9fa, #e9f5ff)',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease-in-out',
                        cursor: 'pointer',
                        position: 'relative',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    className={`dropzone ${isDragActive ? 'active' : ''}`}
                >
                    <input {...getInputProps({ ...options })} />

                    {!hasUploadedFiles && (
                        <>
                            {/* Modern Upload Icon */}
                            <i
                                className="fas fa-cloud-upload-alt"
                                style={{
                                    fontSize: '48px',
                                    color: isDragActive ? '#007bff' : '#6c757d',
                                    marginBottom: '15px',
                                    transition: 'color 0.3s ease-in-out',
                                }}
                            ></i>

                            {/* Main Instruction Text */}
                            <p style={{
                                color: '#333',
                                fontSize: '18px',
                                fontWeight: '600',
                                marginBottom: '5px',
                            }}>
                                Drag & Drop files here
                            </p>

                            {/* Sub Text */}
                            <p style={{
                                color: '#6c757d',
                                fontSize: '14px',
                                fontWeight: '400',
                                marginBottom: '0',
                            }}>
                                or click to browse from your device
                            </p>
                        </>
                    )}

                    {/* Displaying File List */}
                    <ul style={{
                        padding: '0',
                        marginTop: '15px',
                        listStyleType: 'none',
                        width: '100%',
                        textAlign: 'left',
                    }}>
                        {renderDocuList(fieldKey)}
                    </ul>

                    {/* Active State Message */}
                    {isDragActive && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontWeight: 'bold',
                            color: '#007bff',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            padding: '10px 15px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        }}>
                            Release to upload files
                        </div>
                    )}
                </div>
            </div>
        );
    };


    const renderDocuList = (key) => {
        return files[key]?.map((file) => (
            <li key={file.name} style={{ position: 'relative', listStyle: 'none' }}>
                <img style={ImagePreview} src={file.preview} alt={file.name} />
                <span style={FileName}>{file.name}</span>
            </li >
        ));
    };

    const [isFPPExpanded, setFPPExpanded] = useState(true);
    const [isBPPExpanded, setBPPExpanded] = useState(true);
    const [isPANCExpanded, setPANCExpanded] = useState(true);
    const [isTPExpanded, setTPExpanded] = useState(true);

    // const options = country.map(option => ({
    //     value: option.name,
    //     label: option.name,
    //     currency: option.currency,
    // }));

    const [Progressing, setLoding] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('razorpay');
    const handlePaymentChangess = (e) => {
        setPaymentMethod(e.target.value);
    };

    const today = new Date();


    const [options, setOptions] = useState([]);
    useEffect(() => {
        const fatchcountry = async () => {
            try {
                const res = await post(maincountry_list, { type: 'otb', limit: 50000 }, true);
                const response = await res.json();
                const options = response.data.map((option) => ({
                    country_id: option.id,
                    value: option.country_name,
                    label: option.country_name,
                    currency: option.currency,
                }));
                setOptions(options);
            } catch (error) {
                console.log(error);
            }
        };
        fatchcountry();
    }, []);

    return (
        <div className="containe flex flex-col max-w-screen-xl gap-8">
            <form onSubmit={handleSubmit}>
                <div className="apllying-">
                    {formlist.map((traveler, index) => (
                        <div
                            className="apllying-ser"
                            style={{
                                display: traveler.show ? "block" : "none",
                            }}
                        >
                            <header className="pb-4 border-b border-gray-300 border-solid">
                                <h3 className="font-heading text-2xl font-semibold md:text-3xl">
                                    Traveler Details
                                </h3>
                                {/* <h4 className="font-heading text-2xl font-semibold md:text-3xl mt-3">Traveler 1</h4> */}
                            </header>
                            {index !== 0 && (<button className='close-buttonsit' onClick={() => removeitem(index)}>X</button>)}
                            <div className="forms-st fpp">

                                <div className="row mb-3">
                                    {/* <p className="text-sm text-gray-700 mb-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat quaerat minus dolorum ut ab molestias soluta, illo ratione eius rem enim rerum vel itaque nostrum sequi aperiam. Dignissimos, eum debitis?</p> */}
                                    <div className="col-12 col-lg-5 mb-3">
                                        {renderDropzone(
                                            "Front Passport Image",
                                            //   toFrontPassport,
                                            CreateDropzone(
                                                index,
                                                "front_passport_img",
                                                "frontPassport"
                                            ),
                                            "frontPassport"
                                        )}
                                        {renderDropzone(
                                            "Back Passport Image",
                                            //   toBackPassport,
                                            CreateDropzone(
                                                index,
                                                "back_passport_img",
                                                "backPassport"
                                            ),
                                            "backPassport"
                                        )}
                                    </div>
                                    <div className="col-12 col-lg-7 mb-3">
                                        <div className="col-12 mb-3">
                                            <label
                                                className="form-label"
                                                htmlFor="passportNumber"
                                            >
                                                Passport Number
                                                <span className="text-danger">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <i className="fas fa-passport left-start-icon"></i>{" "}
                                                {/* Passport icon */}
                                                <input
                                                    type="text"
                                                    id="passportNumber"
                                                    pattern="^[A-Z]{1}[0-9]{7}$"
                                                    value={traveler.passportNumber}
                                                    onChange={(e) =>
                                                        updateField(
                                                            index,
                                                            "passportNumber",
                                                            e.target.value
                                                        )
                                                    }
                                                    required={traveler.show}
                                                    className="form-control with-icon"
                                                    placeholder="A1234567"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" htmlFor="firstName">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <i className="fas fa-user left-start-icon"></i>{" "}
                                                {/* User icon */}
                                                <input
                                                    type="text"
                                                    id="firstName"
                                                    value={traveler.firstName}
                                                    onChange={(e) =>
                                                        updateField(index, "firstName", e.target.value)
                                                    }
                                                    // required
                                                    className="form-control with-icon"
                                                    placeholder="Enter First Name"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" htmlFor="lastName">
                                                Last Name
                                            </label>
                                            <div className="position-relative">
                                                <i className="fas fa-user left-start-icon"></i>{" "}
                                                {/* Profile icon */}
                                                <input
                                                    type="text"
                                                    id="lastName"
                                                    value={traveler.lastName}
                                                    onChange={(e) =>
                                                        updateField(index, "lastName", e.target.value)
                                                    }
                                                    className="form-control with-icon"
                                                    placeholder="Enter Last Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3 sitdrpdwn">
                                            <label className="form-label" htmlFor="nationality">
                                                Nationality <span className="text-danger">*</span>
                                            </label>

                                            <div className="position-relative">
                                                <i className="fas fa-globe left-start-icon"></i>{" "}
                                                {/* Globe icon */}
                                                <Select
                                                    options={options}
                                                    name="nationality"
                                                    id="nationality"
                                                    value={options.find(
                                                        (option) =>
                                                            option.value === traveler.nationality
                                                    )}
                                                    className="form-control with-icon"
                                                    classNamePrefix="react-select"
                                                    placeholder="Nationality"
                                                    isSearchable
                                                    onChange={(e) =>
                                                        updateField(index, "nationality", e.value)
                                                    }
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            paddingLeft: "1.6rem",
                                                        }),
                                                    }}

                                                />
                                            </div>
                                        </div>

                                        {Passenger && (
                                            <>
                                                <div className="col-12 mb-3 sitdrpdwn">
                                                    <label className="form-label" htmlFor="Passenger_type">
                                                        Passenger Type <span className="text-danger">*</span>
                                                    </label>

                                                    <div className="position-relative">
                                                        <i className="fas fa-user left-start-icon"></i>{" "}
                                                        <select name="Passenger_type" id="Passenger_type" className="form-control with-icon" onChange={(e) =>
                                                            updateField(index, "Passenger_type", e.target.value)
                                                        }>
                                                            <option value=""> Select Passenger type </option>
                                                            <option value="Adult">Adult</option>
                                                            <option value="Child">Child</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="col-12 mb-3">
                                            <label className="form-label" htmlFor="sex">
                                                Sex <span className="text-danger">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <i className="fas fa-venus-mars left-start-icon"></i>{" "}
                                                {/* Gender symbol icon */}
                                                <select
                                                    id="sex"
                                                    value={traveler.sex}
                                                    onChange={(e) =>
                                                        updateField(index, "sex", e.target.value)
                                                    }
                                                    // required
                                                    className="form-select with-icon"
                                                >
                                                    <option value="">Select Gender</option>
                                                    <option value="F">Female</option>
                                                    <option value="M">Male</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label">
                                                Date of Birth<span className="text-danger">*</span>
                                            </label>
                                            <div className="mb-24 position-relative">
                                                <FaRegCalendarAlt className="left-start-icon" />
                                                <DatePicker
                                                    selected={traveler.birthday}
                                                    onChange={(e) =>
                                                        updateField(index, "birthday", e)
                                                    }
                                                    autoComplete="off"
                                                    placeholderText="MM/DD/YYYY"
                                                    dateFormat="MM/dd/yyyy"
                                                    className="form-control with-icon sel-input date_from"
                                                    style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    // required
                                                    showMonthDropdown={true} // Disable month dropdown
                                                    showYearDropdown={true} // Disable year dropdown
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="bg-sec-gray mb-24 mt-24" />
                            </div>
                            <div className="forms-st tp">
                                <div className="row mb-3">
                                    <p className="text-sm text-gray-700 mb-3">
                                        United Arab Emirates{" "}
                                        <a
                                            className="text-blue-600"
                                            href="https://drive.google.com/file/d/1kCVa9iZvIA53wLTfyytlPTgOIJBsVbMm/view?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            here
                                        </a>
                                        . Your visa can get rejected if these guidelines are not
                                        followed.
                                    </p>
                                    <div className="col-12 col-lg-5 mb-3">
                                        {renderDropzone(
                                            "Upload Traveler's Photo",
                                            //   toTravelersPhoto,

                                            CreateDropzone(
                                                index,
                                                "traveler_photo",
                                                "travelersPhoto"
                                            ),
                                            "travelersPhoto"
                                        )}
                                    </div>
                                    {/* <div className="col-12 col-lg-7 mb-3">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="photodays">
                                                    How Many Days Old Is The Image?{" "}
                                                    <span className="text-danger"></span>
                                                </label>
                                                <div className="position-relative">
                                                    <i className="fas fa-camera left-start-icon"></i>
                                                    <input
                                                        type="text"
                                                        id="photodays"
                                                        pattern="^[0-9]{2}$"
                                                        onChange={(e) => setPhotoNumber(e.target.value)}
                                                        className="form-control with-icon"
                                                        placeholder="0 Days"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}


                                    {/* Mother Name */}
                                    <div className="col-6 col-lg-7 mb-3">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="motherName">Mother Name</label>
                                            <input
                                                type="text"
                                                id="motherName"
                                                onChange={(e) =>
                                                    updateField(index, "motherName", e.target.value)
                                                }

                                                className="form-control"
                                                placeholder="Enter Mother Name"
                                            />
                                        </div>


                                        {/* Father Name */}

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="fatherName">Father Name</label>
                                            <input
                                                type="text"
                                                id="fatherName"
                                                onChange={(e) =>
                                                    updateField(index, "fatherName", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Enter Father Name"
                                            />
                                        </div>


                                        {/* Place of Birth */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="placeOfBirth">Place of Birth</label>
                                            <input
                                                type="text"
                                                id="placeOfBirth"
                                                onChange={(e) =>
                                                    updateField(index, "placeOfBirth", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Enter Place of Birth"
                                            />
                                        </div>


                                        {/* Spouse Name */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="spouseName">Spouse Name</label>
                                            <input
                                                type="text"
                                                id="spouseName"
                                                onChange={(e) =>
                                                    updateField(index, "spouseName", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Enter Spouse Name"
                                            />
                                        </div>


                                        {/* Travel Date */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="travelDate">Travel Date</label>
                                            <input
                                                type="date"
                                                id="travelDate"
                                                onChange={(e) =>
                                                    updateField(index, "travelDate", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Select Travel Date"
                                            />
                                        </div>


                                        {/* Optional Entry Point */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="entryPoint">Entry Point (Optional)</label>
                                            <input
                                                type="text"
                                                id="entryPoint"
                                                onChange={(e) =>
                                                    updateField(index, "entryPoint", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Enter Entry Point"
                                            />
                                        </div>


                                        {/* Optional Exit Point */}
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="exitPoint">Exit Point (Optional)</label>
                                            <input
                                                type="text"
                                                id="exitPoint"
                                                onChange={(e) =>
                                                    updateField(index, "exitPoint", e.target.value)
                                                }
                                                className="form-control"
                                                placeholder="Enter Exit Point"
                                            />
                                        </div>

                                    </div>

                                </div>
                                <hr className="bg-sec-gray mb-24 mt-24" />
                            </div>

                            <div className="forms-st">
                                <div className="row mb-3">
                                    <p className="text-sm text-gray-700 mb-3">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Ea ut, commodi quidem exercitationem quo sunt ipsum id
                                        corrupti facere nobis ?
                                    </p>
                                    <div className="col-12 col-lg-5 mb-3">
                                        {renderDropzone(
                                            "Upload Traveler's PAN Card",
                                            //   toTravelersPanCard,

                                            CreateDropzone(
                                                index,
                                                "pen_card_photo",
                                                "travelersPAN"
                                            ),
                                            "travelersPAN"
                                        )}
                                    </div>

                                    <div className="col-12 col-lg-7 mb-3">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="panNumber">
                                                India PAN Card Number{" "}
                                                <span className="text-danger">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <i className="fas fa-id-card left-start-icon"></i>{" "}
                                                {/* PAN Card icon */}
                                                <input
                                                    type="text"
                                                    id="panNumber"
                                                    pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$"
                                                    onChange={(e) => updateField(index, "panNumber", e.target.value)}
                                                    // required
                                                    className="form-control with-icon"
                                                    placeholder="ABCDE1234F" // Optional example placeholder
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                            <label className="form-label" htmlFor="panNumber">India PAN Card Number <span className="text-danger">*</span></label>
                                            <input type="text" id="panNumber" pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$" onChange={(e) => setPenNumber(e.target.value)} required className="form-control" />
                                        </div> */}
                                </div>
                            </div>

                            <section className="stack-y mt-64">
                                <header>
                                    <h4 className="font-heading text-xl font-semibold mb-8">
                                        Answer Additional Required Questions
                                    </h4>
                                </header>
                                <input
                                    type="hidden"
                                    name="travelers.0.application.completedSteps.ADDITIONAL_QUESTIONS"
                                    value="true"
                                />
                                <div>
                                    <input
                                        type="hidden"
                                        name="travelers.0.application.bouncerRequirement.additionalQuestions.0.key"
                                        value="occupation"
                                    />
                                    <div aria-required="false" class="flex flex-col gap-2">
                                        <div class="stack-y group gap-2">
                                            <p class="text-sm text-gray-700 px-2 mt-3 mb-2">
                                                What is the traveler's occupation (optional)?
                                            </p>
                                            <div class="position-relative select">
                                                <i class="fas fa-briefcase left-start-icon"></i>
                                                <select
                                                    id="occupation"
                                                    name="travelers.0.application.bouncerRequirement.additionalQuestions.0.answer"
                                                    class="form-select wizard-required w-100 with-icon"
                                                >
                                                    <option value="">Select an item</option>
                                                    <option value="architect">Architect</option>
                                                    <option value="associate officer">
                                                        Associate Officer
                                                    </option>
                                                    <option value="business">Business</option>
                                                    <option value="businesswoman">Businesswoman</option>
                                                    <option value="none">Child</option>
                                                    <option value="clinical scientist">
                                                        Clinical Scientist
                                                    </option>
                                                    <option value="director">Director</option>
                                                    <option value="engineer">Engineer</option>
                                                    <option value="executive">Executive</option>
                                                    <option value="flight attendant">
                                                        Flight Attendant
                                                    </option>
                                                    <option value="house wife">Housewife</option>
                                                    <option value="journalist">Journalist</option>
                                                    <option value="lawyer">Lawyer</option>
                                                    <option value="manager">Manager</option>
                                                    <option value="medical doctors">
                                                        Medical Doctors
                                                    </option>
                                                    <option value="photographer">Photographer</option>
                                                    <option value="physician">Physician</option>
                                                    <option value="pilot">Pilot</option>
                                                    <option value="retired">Retired</option>
                                                    <option value="sales representative">
                                                        Sales Representative
                                                    </option>
                                                    <option value="sales specialist">
                                                        Sales Specialist
                                                    </option>
                                                    <option value="secretary">Secretary</option>
                                                    <option value="senior manager">
                                                        Senior Manager
                                                    </option>
                                                    <option value="services">Services</option>
                                                    <option value="soldier">Soldier</option>
                                                    <option value="student / not allowed to work">
                                                        Student / Not Allowed to Work
                                                    </option>
                                                    <option value="teacher">Teacher</option>
                                                    <option value="university professor">
                                                        University Professor
                                                    </option>
                                                    <option value="vice president">
                                                        Vice President
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <br /> */}
                            {/* <button type="submit" className="cus-btn">Submit</button> */}
                        </div>
                    ))}
                    {formlist.filter((item) => item.show).length !==
                        10 && (<div className="d-flex justify-content-end gap-3 my-4 mx-4">
                            {/* <div className="stack-x"> */}
                            {/* Add Another Traveler Button */}
                            <button
                                type="button"
                                className="cus-btn-outline"
                                onClick={addNewTraveler}
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="far"
                                    data-icon="user-plus"
                                    className="svg-inline--fa fa-user-plus fa-fw"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 640 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M224 48a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 96h91.4c65.7 0 120.1 48.7 129 112H49.3c8.9-63.3 63.3-112 129-112zm0-48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zM504 312c0 13.3 10.7 24 24 24s24-10.7 24-24V248h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H552V136c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H440c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"
                                    ></path>
                                </svg>
                                Add Another Traveler
                            </button>
                        </div>
                        )}
                    <div className="col-11 m-auto">
                        <div className="">
                            <div className="col-md-11 m-auto rounded border border-secondary bg-white shadow-md px-3 py-2 mb-3">
                                {/* Visa Information Section */}
                                <section className="py-2">
                                    <header>
                                        <h3 className="fw-semibold fs-5">Visa Information</h3>
                                    </header>
                                    <div className="d-flex">
                                        <ul className="list-unstyled flex-grow-1">
                                            <li className="py-1">{visaDetail.about || "N/A"}</li>
                                            <li>
                                                Travel city: <span>{jsonObject.going_from}</span> -{" "}
                                                <span>{jsonObject.going_to}</span>
                                            </li>
                                            <li>
                                                { }
                                                Travel Dates: <span>{formattedDate}</span> -{" "}
                                                <span>{formattedreturnDate}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </section>

                                {/* Expected Visa Approval Section */}
                                <section className="py-2">
                                    <header>
                                        <h3 className="fw-semibold fs-5 mb-1">
                                            Expected Visa Approval
                                        </h3>
                                    </header>
                                    <p className="fw-semibold">
                                        <svg
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="far"
                                            data-icon="calendar"
                                            className="svg-inline--fa fa-calendar me-2"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                            style={{ width: "1em", height: "1em" }}
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"
                                            />
                                        </svg>
                                        {visaDetail.processing_time || "N/A"} , if submitted now!
                                    </p>
                                </section>

                                {/* Know Before You Pay Section */}
                                <section className="py-2">
                                    <header>
                                        <h3 className="fw-semibold fs-5">Know Before You Pay</h3>
                                    </header>
                                    <ul className="list-unstyled mb-0">
                                        <li className="d-flex align-items-start py-2">
                                            <div className="me-2">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="far"
                                                    data-icon="circle"
                                                    className="svg-inline--fa fa-circle text-success"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ width: "1em", height: "1em" }}
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <p className="fw-semibold">
                                                    Auto-validation upon submission
                                                </p>
                                                <p className="small">
                                                    .. performs automated validation after submission.
                                                    We will let you know if there are any problems with
                                                    the application.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-start py-2">
                                            <div className="me-2">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="far"
                                                    data-icon="circle"
                                                    className="svg-inline--fa fa-circle text-success"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ width: "1em", height: "1em" }}
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <p className="fw-semibold">
                                                    Visa processed within 30 seconds
                                                </p>
                                                <p className="small">
                                                    .. automatically processes your visa.
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-start py-2">
                                            <div className="me-2">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="far"
                                                    data-icon="circle"
                                                    className="svg-inline--fa fa-circle text-warning"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ width: "1em", height: "1em" }}
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
                                                    />
                                                </svg>
                                            </div>

                                            <div>
                                                <p className="fw-semibold">
                                                    Non-refundable after you pay
                                                </p>
                                                <p className="small">
                                                    If canceled after payment, you will not be refunded.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                            </div>

                            {Isinsurense && (
                                <>
                                    <div className="mb-2">
                                        <input
                                            type="checkbox"
                                            id="confirmBooking"
                                            checked={isConfirmed}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="confirmBooking" className="ms-2">
                                            I want to buy insurance
                                        </label>
                                    </div>
                                </>
                            )}

                            

                            <div className="col-md-11 m-auto rounded border border-secondary bg-white shadow-md px-3 py-2 mb-3">
                                <div className="">
                                    <header>
                                        <h3 className="fw-semibold fs-5">Price Details</h3>
                                    </header>
                                    <div className="p-3 border-0">

                                        <div className="fw-bold d-flex justify-content-between pt-2">
                                            {
                                                
                                                
                                                Isinsurense && isConfirmed && (
                                                    <>
                                                        <div>Insurance Fees</div>
                                                        <div> {formlist.filter((item) => item.show).length} X  ₹{49 * formlist.filter((item) => item.show).length}</div>

                                                    </>
                                                )
                                            }
                                        </div>


                                        <div className="fw-bold d-flex justify-content-between pt-2">
                                            <div>Visa fees</div>
                                            <div>
                                               {formlist.filter((item) => item.show).length} X   ₹
                                                {(Number(visaDetail.amount) + Number(setting != null ? setting.visa_agency_charge : "0")) *
                                                    formlist.filter((item) => item.show).length}
                                            </div>
                                        </div><br />
                                          <hr />
                                        <div className="fw-bold d-flex justify-content-between pt-2">
                                            <div>Total Amount</div>
                                            <div>
                                                ₹
                                                {(Number(visaDetail.amount) + Number(setting != null ? setting.visa_agency_charge : "0") + Number((Isinsurense && isConfirmed) ? 49 : 0)) *
                                                    formlist.filter((item) => item.show).length}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="pt-4 pb-1">
                                        <button
                                            type="button"
                                            className="cus-btn w-100">
                                            Continue
                                        </button>
                                    </div> */} 

                                    <div className="row pt-4 border-top">
                                        <div className="col-sm-12 mb-3">
                                            <div className="final_step">
                                                <div className="radio-group-sit">
                                                    <h5 className="mb-2">Choose Payment Method</h5>
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-sm-9 mb-3">
                                                            <div className="radio-container">
                                                                <label
                                                                    className={
                                                                        paymentMethod === "razorpay"
                                                                            ? "active"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        name="payment"
                                                                        value="razorpay"
                                                                        checked={paymentMethod === "razorpay"}
                                                                        onChange={handlePaymentChangess}
                                                                    />
                                                                    <SiRazorpay />
                                                                    <p className="textrr">Razor Pay</p>
                                                                </label>

                                                                <label
                                                                    className={
                                                                        paymentMethod === "wallet" ? "active" : ""
                                                                    }
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        name="payment"
                                                                        value="wallet"
                                                                        checked={paymentMethod === "wallet"}
                                                                        onChange={handlePaymentChangess}
                                                                    />
                                                                    <CiWallet />
                                                                    <p className="textrr">Wallet</p>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mb-3">
                                                            {Progressing ? (
                                                                <Progress />
                                                            ) : (
                                                                <div className="col-12 float-end">
                                                                    <button
                                                                        type="submit"
                                                                        className="form-wizard-next-btn cus-btn cus-btn-strng w-100"
                                                                    >
                                                                        Apply Now
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default TabComponent;
