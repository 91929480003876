import React from 'react';
import Slider from 'react-slick';
import '../testimonial/testimonial.css';
import user1 from "../../../assets/images/user/user-1.png";
import user2 from "../../../assets/images/user/user-2.png";
import user3 from "../../../assets/images/user/user-3.png";



const testimonialsData = [
    {
        id: 1,
        text: "Lorem ipsum dolor sit amet consectetur. Eget ornare ac eleifend leo mauris suspendisse.",
        name: "Mr John Deo",
        position: "CEO at FlyNow",
        userImages: [
            user1,
            user2,
            user3,
        ],
    },
    {
        id: 2,
        text: "Bibendum suspendisse proin aliquet porttitor sed vulputate proin ultrices et.",
        name: "Mr John Deo",
        position: "CEO at FlyNow",
        userImages: [
            user2,
            user3,
            user1,
        ],
    },
    // Add more testimonials as needed
];

const TestimonialsSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <section className="testimonials p-40">
            <div className="container">
                <div className="testimonials-box bg-white light-shadow br-20">
                    <div className="row align-items-center row-gap-4">
                        <div className="col-xl-4">
                            <div className="text-area">
                                <h5 className="color-primary mb-16">Testimonials</h5>
                                <h3 className="h1 bold lightest-black">What our clients think about us?</h3>
                            </div>
                        </div>
                        <div className="col-xl-7 offset-xl-1">
                            <Slider {...settings}>
                                {testimonialsData.map((testimonial) => (
                                    <div key={testimonial.id} className="review-block bg-lightest-gray pad-32 br-10">
                                        <div className="user-image d-flex mb-32">
                                            {testimonial.userImages.map((imgSrc, index) => (
                                                <img key={index} src={imgSrc} alt={`User ${index + 1}`} />
                                            ))}
                                        </div>
                                        <h6 className="light-black mb-32">{testimonial.text}</h6>
                                        <h5 className="light-black mb-2">{testimonial.name}</h5>
                                        <p className="dark-gray">{testimonial.position}</p>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
