import React, { useState } from 'react';
import '../privacy-policy/privacy-policy.css'

const PrivacyPolicyCon = () => {
    // State to handle the collapse of the sidebar sections
    const [openSection, setOpenSection] = useState(null);

    // Toggle function to open or close sections
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <section className="privacy-policy p-60">
            <div className="container">
                <div className="row">
                    {/* Sidebar */}
                    <div className="col-xl-4 col-lg-4 mb-lg-0 mb-24">
                        <div className="pp-sidebar bg-white p-24 light-shadow br-20">

                            {/* Overview Block */}
                            <div className="filter-block box-1">
                                <div className="title mb-24 d-flex align-items-center justify-content-between"
                                    data-count="1"
                                    onClick={() => toggleSection(1)}>
                                    <h4 className="color-black">Overview</h4>
                                    <i className={`fal ${openSection === 1 ? 'fa-chevron-up' : 'fa-chevron-down'} color-primary`}></i>
                                </div>
                                {openSection === 1 && (
                                    <div className="content-block mb-24">
                                        <h5 className="dark-gray mb-16">We care about your privacy</h5>
                                        <h5 className="dark-gray">Our Privacy Policy in brief</h5>
                                    </div>
                                )}
                                <hr className="bg-sec-gray mb-24" />
                            </div>

                            {/* About Block */}
                            <div className="filter-block box-2">
                                <div className="title mb-24 d-flex align-items-center justify-content-between"
                                    data-count="2"
                                    onClick={() => toggleSection(2)}>
                                    <h4 className="color-black">About</h4>
                                    <i className={`fal ${openSection === 2 ? 'fa-chevron-up' : 'fa-chevron-down'} color-primary`}></i>
                                </div>
                                {openSection === 2 && (
                                    <div className="content-block mb-24">
                                        <h5 className="dark-gray mb-16">About the Flynow</h5>
                                        <h5 className="dark-gray mb-16">Providing Your Personal Info</h5>
                                        <h5 className="dark-gray mb-16">Third Party Websites</h5>
                                        <h5 className="dark-gray">Updates</h5>
                                    </div>
                                )}
                                <hr className="bg-sec-gray mb-24" />
                            </div>

                            {/* More Information Block */}
                            <div className="filter-block box-3">
                                <div className="title mb-24 d-flex align-items-center justify-content-between"
                                    data-count="3"
                                    onClick={() => toggleSection(3)}>
                                    <h4 className="color-black">More Information</h4>
                                    <i className={`fal ${openSection === 3 ? 'fa-chevron-up' : 'fa-chevron-down'} color-primary`}></i>
                                </div>
                                {openSection === 3 && (
                                    <div className="content-block">
                                        <h5 className="dark-gray mb-16">Legal Basis for using your personal information</h5>
                                        <h5 className="dark-gray mb-16">Cookies</h5>
                                        <h5 className="dark-gray">How to Contact Us</h5>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-xl-8 col-lg-8">
                        <div className="bg-white p-24 light-shadow br-20 mb-24">
                            <h5 className="light-black mb-24">We care about your privacy</h5>
                            <hr className="bg-light-gray w-50 mb-24" />
                            <p className="dark-gray fw-400">
                                Lorem ipsum dolor sit amet consectetur. Maecenas lobortis gravida lobortis nulla nibh...
                            </p>
                        </div>

                        <div className="bg-white p-24 light-shadow br-20 mb-24">
                            <h5 className="light-black mb-24">Our Privacy Policy in brief</h5>
                            <hr className="bg-light-gray w-50 mb-24" />
                            <p className="dark-gray fw-400 mb-24">
                                Lorem ipsum dolor sit amet consectetur. Maecenas lobortis gravida lobortis nulla nibh...
                            </p>
                            <h6 className="light-black mb-8">Our Privacy Policy in brief</h6>
                            <ul className="mb-24">
                                <li className="dark-gray">Lorem ipsum dolor sit amet consectetur...</li>
                                <li className="dark-gray">Lorem ipsum dolor sit amet consectetur...</li>
                            </ul>
                        </div>

                        <div className="bg-white p-24 light-shadow br-20 mb-24">
                            <h5 className="light-black mb-24">Providing your personal information</h5>
                            <hr className="bg-light-gray w-50 mb-24" />
                            <p className="dark-gray fw-400">
                                Lorem ipsum dolor sit amet consectetur. Maecenas lobortis gravida lobortis nulla nibh...
                            </p>
                        </div>

                        {/* Add more content sections similarly */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicyCon;
