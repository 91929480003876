
export const SERVER_URL = "https://flightbackend.readytouse.in/";
// export const SERVER_URL = "http://192.168.31.24:1028/";
export const API_BASE_URL = SERVER_URL + 'api/';
export const IMAGE_BASE_URL = SERVER_URL + 'public/';
export const siteconfig = "setting/settings";


export async function AIR_2_URL() {
    let setting=null;
    let settingFromSession = sessionStorage.getItem('settting');
    if (settingFromSession && settingFromSession != null) {
         setting = JSON.parse(settingFromSession);
    }
    return setting.etrav_api_prod_on==1?setting.etrav_api_uat_url+"/airlinehost/AirAPIService.svc/JSONService/":setting.etrav_api_prod_url+"/airlinehost/AirAPIService.svc/JSONService/";
}
export async function AIR_3_URL() {
    let setting=null;
    let settingFromSession = sessionStorage.getItem('settting');
    if (settingFromSession && settingFromSession != null) {
         setting = JSON.parse(settingFromSession);
    }
    return setting.etrav_api_prod_on==1?setting.etrav_api_uat_url+"/tradehost/TradeAPIService.svc/JSONService/":setting.etrav_api_prod_url+"/tradehost/TradeAPIService.svc/JSONService/";
}



// async function geturl() {
//     const options = {
//         method: "GET",
//     };

//     try {
//         const response = await fetch(API_BASE_URL + siteconfig, options);
//         if (response.status === 404) {
//             throw new Error("Not Found: Resource Not Found");
//         } else {
//             const data = await response.json();
//             if (data.data.etrav_api_prod_on == '1') {
//                 return data.data.etrav_api_prod_url;
//             } else {
//                 return data.data.etrav_api_uat_url;
//             }
//         }
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         return null;
//     }
// }

// let url = '';
// async function initializeUrl() {
//     await geturl()
//         .then(data => {
//             url = data;
//         })
//         .catch(error => console.error("Error in geturl call:", error));
//         console.log(url)
// }
// initializeUrl();

export const dashboard = "home/";
export const admin_profile = "auth/admin_profile";
export const admin_profile_update = "auth/admin_profile_update";
export const admin_password_update = "auth/admin_password_update";

export const account_login = "user/account_login";
export const create_account = "user/create_account";
export const update_account = "user/update_account";
export const update_password = "user/update_password";
export const users_profile = "user/users_profile";
export const account_logout = "user/logout";
export const search_visa = "visa/search";
export const details_visa = "visa/get_details";
export const apply_visa = "visa/apply_visa";
export const applied_visa_list = "visa/applied_list";

export const oktb_create = "oktb/add";
export const applied_oktb_list = "oktb/applied_list";

export const support_add = "support/add";

export const add_faq_category = "faq/add";
export const faq_category_list = "faq/list";
export const faq_category_delete = "faq/delete";

export const faq_add = "faq/add_faq";
export const faq_list = "faq/faq_list";
export const faq_delete = "faq/faq_delet";

export const language_add = "language/add";
export const language_list = "language/list";
export const language_delete = "language/delete";

export const updateSettings = "setting/updateSettings";

export const add_subscription = "subscription/add";
export const list_subscription = "subscription/list";
export const del_subscription = "subscription/del";
export const purchage_subscription_list = "subscription/purchage_subscription_list";

export const add_question = "question/add";
export const list_question = "question/list";
export const del_question = "question/delete";

export const country_list = "country/list";
export const maincountry_list = "country/mainlist";
export const booking_add = "booking/add";
export const booking_update = "booking/update";
export const booking_list = "booking/list";
export const ticket_details = "booking/get_ticket_details";
export const booking_cancle = "booking/cancle_booking";

export const wallet_add = "wallet/add";
export const wallet_list = "/wallet/list";
export const airline_list = "/airline/list";
export const listAirlinePrices = "/airline/listAirlinePrices";
export const airline_code = "/airline/singledata";

export const third_party = "third_party/fetch";
// export const AIR_2_URL = "http://uat.etrav.in/airlinehost/AirAPIService.svc/JSONService/";
export const AIR_SEARCH = "Air_Search";
export const AIR_FARERULE = "Air_FareRule";
export const AIR_REPRICE = "Air_Reprice";
export const AIR_PAY = "AddPayment";
export const AIR_BOOKING = "Air_TempBooking";
export const AIR_TICKETING = "Air_Ticketing";
export const AIR_REPRINT = "Air_Reprint";
export const send_notification = "comman/send_notification";

/////////////////////////////////////////////////// THIRD PARTY APIS ////
export const AIR_IQ_LOGIN = "login";
export const AIR_IQ_SEARCH = "search";
export const GET_APIS = "comman/get_api";
export const AIR_IQ = "https://omairiq.azurewebsites.net/";


