import React, { useState, useEffect } from 'react';
import '../flight-booking-main/flight-booking-main.css';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { AIR_2_URL,AIR_3_URL, AIR_REPRICE, AIR_BOOKING, AIR_TICKETING, AIR_REPRINT, third_party, booking_add, booking_update, wallet_add, users_profile, AIR_PAY } from "../../../API/endpoints";
import { post } from "../../../API/airline";
import { post as HelperPost, get } from "../../../API/apiHelper";
import { razarpaypayment } from "../../../API/utils";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../../assets/images/logo.png"
import Progress from "../../../component/Loading";
import country from '../../../widget/country';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiWallet } from "react-icons/ci";
import { SiRazorpay } from "react-icons/si";
import { format } from 'date-fns';


const FlightBookingForm = ({ data, bookingamount }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [Progressing, setLoding] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('razorpay');

    const Search_Key = data[0].Search_Key;
    const adultcount = data[0].adultcount.adult;
    const childcount = data[0].adultcount.child;
    const infantcount = data[0].adultcount.infant;

    const [isConfirmed, setIsConfirmed] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsConfirmed(e.target.checked);
    };

    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession != null) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
        const count = Number(adultcount) + Number(childcount) + Number(infantcount);
        // const newPassengers = Array.from({ length: count }, () => ({ ...initialPassengerData }));

        const createPassengerArray = () => {
            const passengersArray = [];

            // Add adults
            for (let i = 0; i < Number(adultcount); i++) {
                passengersArray.push({ ...initialPassengerData, passenger_type: '0' });
            }

            // Add children
            for (let i = 0; i < Number(childcount); i++) {
                passengersArray.push({ ...initialPassengerData, passenger_type: '1' });
            }

            // Add infants
            for (let i = 0; i < Number(infantcount); i++) {
                passengersArray.push({ ...initialPassengerData, passenger_type: '2' });
            }
            return passengersArray;
        };
        setPassengers(createPassengerArray());
    }, []);

    const initialPassengerData = {
        passenger_type: '',
        gender: '',
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        nationality: '',
        Age: '',
        Passenger_Mobile: '',
        WhatsAPP_Mobile: '',
        dateOfBirth: '',
        passport_Number: '',
        Passport_Issuing_Country: '',
        Passport_Expiry: '',
        pancard_Number: '',
        flightNumber: '',
    };
    const [passengers, setPassengers] = useState([initialPassengerData]);

    // const handleInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedPassengers = [...passengers];
    //     updatedPassengers[index][name] = value;
    //     setPassengers(updatedPassengers);
    // };

    const handleInputChange = (eventOrOption, index) => {
        const updatedPassengers = [...passengers];

        // Check if `eventOrOption` is from a standard input or a `react-select` component
        if (eventOrOption.target) {
            // Standard input field
            const { name, value } = eventOrOption.target;
            updatedPassengers[index][name] = value;
        } else {
            // `react-select` field
            const { name, value } = eventOrOption; // `name` and `value` from `selectedOption`
            updatedPassengers[index][name] = value;
        }
        setPassengers(updatedPassengers);
    };


    const [uData, setUData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await get(users_profile, true);
                if (!response.ok) {
                    const errorMsg = await response.text();
                    throw new Error(`Error ${response.status}: ${errorMsg}`);
                }
                const data = await response.json();
                setUData(data.data)
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };
        fetchUserData();
    }, []);


    const addPassenger = () => {
        setPassengers([...passengers, initialPassengerData]);
    };

    const removePassenger = (index) => {
        const updatedPassengers = passengers.filter((_, i) => i !== index);
        setPassengers(updatedPassengers);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isConfirmed) {
            return alert("Please confirm by checking the box before booking.");

        }
        setLoding(true);

        let Fareinfo = [];
        data.forEach((trip, index) => {
            Fareinfo.push({
                "Flight_Key": trip.flight.Flight_Key,
                "Fare_Id": trip.fareid,
            });
        });

        const payload = {
            "Auth_Header": {
                "UserId": "viviantravelsuat",
                "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                "IP_Address": "12333333",
                "Request_Id": "5500833959053023879",
                "IMEI_Number": "9536615000"
            },
            "Search_Key": Search_Key,
            "AirRepriceRequests": Fareinfo,
            "Customer_Mobile": "9173456988",
            "GST_Input": false,
            "SinglePricing": true
        }


        const api_url = await AIR_2_URL() + AIR_REPRICE;
        const response = await post(third_party, JSON.stringify(payload), api_url);
        const airrresponsedata = await response.json();

        if (airrresponsedata.data.AirRepriceResponses== null) {
            setLoding(false);
            return alert(airrresponsedata.data.Response_Header.Error_Desc);
            
        }


        const f_key = airrresponsedata.data.AirRepriceResponses[0].Flight.Flight_Key;
        const g_c = airrresponsedata.data.AirRepriceResponses[0].Flight.Fares[0].FareDetails[0].Gross_Commission;
        const n_c = airrresponsedata.data.AirRepriceResponses[0].Flight.Fares[0].FareDetails[0].Net_Commission;


        let Tempbookingflightdetails = []
        airrresponsedata.data.AirRepriceResponses.forEach((Details, index) => {
            Tempbookingflightdetails.push(
                {
                    "Search_Key": Search_Key,
                    "Flight_Key": Details.Flight.Flight_Key,
                    "BookingSSRDetails": [
                        {
                            "Pax_Id": "1",
                            "SSR_Key": "UBDtDvuR/pNURyhipA0W+UZrZGOSyCC+n6A1pE1bmLOC3B2qh9c6kyR0zc2mLjzv/K7Amwf5Ag8uau5ZIquv6zpLb0Jwq7aF1XajGzePXmBVxFaqAiI6rcVobN3FkSIjDToLc3H2eQyYqIFOkI6WLksghOmMdkmzFjGez5cSNWkzxh7+feJLahMuExLbyamZlaA1KSXASHimsM9RJSrIMDT+yiwT3kDFb682yfYAkdfKfRdQjsx2VI3w5nNJemQoaVEFWmD56advxGX0Et8UkY2Tj3GMOFSachBr5fm4kxqkYpTOyZoxHW2lE2F8TM5Yxt9wmox1RhkjAL/QQh2x5VozECGEowWlQaAN0skIC6IFJsVP1by2aSy1UKEu2XfeMgJjkz6XiWp+B8ADARKFy09lnMdC7wQJIF5TATUqdGyf713BMHPz8/mASSQrSAXFw0BEzSLXNoMF7w5/IxiwHyKjO1vaM5hW0F0D++bJ7FqNOo0s0eK6U0DfM/+Le0OWVedgXGKgIg5MyNTy+b/X7irynRzWmRhFxLzSYEG696QPCDVCmT86ZVM9xjgUlzfskOouGKCuXOBabYYHNrXRVv261Uo3/4mrEfdu4yyO0TvWk7KyzlfxfIE8EKV2JvVQc5OcRPStCAr6OGAntBThGQFmKiSqs3z4t61Xs/nqWhph1Gu3pZJ9eYZtnoW6s+r4x8dXs0lJzjgWcklBAmEdeA=="
                        }
                    ]
                }

            );
        });



        let fix_amt = Number(n_c) - Number(g_c);

        const PAX_Details = passengers.map((passenger, index) => ({
            "Pax_Id": index + 1,
            "Pax_type": passenger.passenger_type,
            "Title": passenger.title,
            "First_Name": passenger.firstName,
            "Last_Name": passenger.lastName,
            "Gender": passenger.gender === 'Male' ? 0 : 1, // Assuming gender is 'Male' or 'Female'
            "Age": passenger.Age,
            "DOB": passenger.dateOfBirth,
            "Passport_Number": passenger.passport_Number,
            "Passport_Issuing_Country": passenger.Passport_Issuing_Country,
            "Passport_Expiry": passenger.Passport_Expiry,
            "Nationality": passenger.nationality,
            "Pancard_Number": passenger.pancard_Number,
            "FrequentFlyerDetails": {
                "AirLineCode": "6E", // Example airline code
                "FrequentFlyerNumber": "1" // Example frequent flyer number
            }
        }));

        const bookingpayload = {
            "Auth_Header": {
                "UserId": "viviantravelsuat",
                "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                "IP_Address": "12333333",
                "Request_Id": "5500833959053023879",
                "IMEI_Number": "9536615000"
            },
            "Customer_Mobile": "9001291792",
            "Passenger_Mobile": "9694257634",
            "WhatsAPP_Mobile": 9694257634,
            "Passenger_Email": "Deepakkumawat@44711@gmail.com",
            "PAX_Details": PAX_Details,
            "GST": false,
            "GST_Number": "",
            "GST_HolderName": "GST Holder Name",
            "GST_Address": "GST Address",
            "BookingFlightDetails": Tempbookingflightdetails,
           
            "CostCenterId": 1,
            "ProjectId": 1,
            "BookingRemark": "MAA-TCR  18-Oct-2024",
            "CorporateStatus": 0,
            "CorporatePaymentMode": 0,
            "MissedSavingReason": null,
            "CorpTripType": null,
            "CorpTripSubType": null,
            "TripRequestId": null,
            "BookingAlertIds": null
        }
        const url = await AIR_2_URL() + AIR_BOOKING;
        const res = await post(third_party, JSON.stringify(bookingpayload), url);
        const booking_data = await res.json();

        if (booking_data.data.Booking_RefNo != null) {
            setLoding(false);
            const RefNo = booking_data.data.Booking_RefNo;
            const formData = {
                user_id: userData.id,
                Booking_RefNo: RefNo,
                PAX_Details: JSON.stringify(PAX_Details),
            }
            const response = await HelperPost(booking_add, formData, true);
            const booking_add_data = await response.json();
            if (booking_add_data.status == false) {
                console.error('Error:', booking_add_data.message);
                alert(booking_add_data.message)
            } else {
                if (booking_add_data.status == true) {

                    let a_amount = '0';
                    let finalAmount = bookingamount;
                    if (uData.type == 2) {
                        a_amount = uData.agents ? uData.agents.flight_booking_c : ''
                        finalAmount = Number(bookingamount) - (Number(fix_amt) - (Number(fix_amt) * (Number(a_amount) / 100)));
                    }

                    const ordre_id = Math.floor(10000000 + Math.random() * 90000000);
                    if (paymentMethod == 'razorpay') {
                        razarpaypayment(ordre_id, finalAmount, "Test Transaction", RefNo, (response) => {
                            if (response.razorpay_payment_id && response.razorpay_payment_id != null) {
                                proceed_booking('Rezorpay', bookingamount, RefNo, JSON.stringify(response), finalAmount)
                            }
                        });
                    } else {
                        if (userData.wallet >= finalAmount) {
                            proceed_booking('Wallet', bookingamount, RefNo, 'N/A', finalAmount)
                        } else {
                            toast.error('Your Wallet Balance is low');
                        }
                    }
                } else {
                    alert("Somthing Went Wrong")
                }
            }
        } else {
            setLoding(false);
            alert(booking_data.data.Response_Header.Error_InnerException)
        }

        setLoding(false);
    };



    const proceed_booking = async (paytype, amount, RefNo, rzres, finalAmount) => {

        let paymentrecord;
        const amount_payload = {
            "Auth_Header": {
                "UserId": "viviantravelsuat",
                "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                "IP_Address": "12333333",
                "Request_Id": "5500833959053023879",
                "IMEI_Number": "9536615000"
            },
            "ClientRefNo": "Testing Team",
            "RefNo": RefNo,
            "TransactionType": 0,
            "ProductId": "1"
        }

        const addpayment_api_url = await AIR_3_URL() + AIR_PAY;
        const addpayment_res = await post(third_party, JSON.stringify(amount_payload), addpayment_api_url);
        paymentrecord = await addpayment_res.json();

        if (addpayment_res.ok) {

            const ticketingpayload = {
                "Auth_Header": {
                    "UserId": "viviantravelsuat",
                    "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                    "IP_Address": "12333333",
                    "Request_Id": "5500833959053023879",
                    "IMEI_Number": "9536615000"
                },
                "Booking_RefNo": RefNo,
                "Ticketing_Type": "1"
            }
            const api_url = await AIR_2_URL() + AIR_TICKETING;
            const res = await post(third_party, JSON.stringify(ticketingpayload), api_url);
            let trecord = '';
            if (res.ok) {
                const rpayload = {
                    "Auth_Header": {
                        "UserId": "viviantravelsuat",
                        "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                        "IP_Address": "12333333",
                        "Request_Id": "5500833959053023879",
                        "IMEI_Number": "9536615000"
                    },
                    "Booking_RefNo": RefNo,
                    "Airline_PNR": ""
                }
                const aurl = await AIR_2_URL() + AIR_REPRINT;
                const resp = await post(third_party, JSON.stringify(rpayload), aurl);
                trecord = await resp.json();
            }

            const formData = {
                Booking_RefNo: RefNo,
                Amount: amount,
                paying_method: paytype,
                amount_status: 'paid',
                amount_res: rzres,
                Ticket_Details: JSON.stringify(trecord.data),
                amount_api_res:JSON.stringify(paymentrecord.data),
            }
            const apiresponse = await HelperPost(booking_update, formData, true);
            if (apiresponse.ok) {

                if (paytype == "Wallet") {
                    const formData = {
                        user_id: userData.id,
                        order_id: RefNo,
                        transaction_type: 'Ticket Booking',
                        amount: amount,
                        payment_getway: paytype,
                        details: 'Ticket Booking',
                        type: '2',
                        status: 'Success',
                    }
                    await HelperPost(wallet_add, formData, true);


                    let user = sessionStorage.getItem('userData');
                    user = user ? JSON.parse(user) : {};
                    let finlaamount = (Number(user.model.wallet) - Number(amount));
                    user.model.wallet = finlaamount
                    sessionStorage.setItem('userData', JSON.stringify(user));
                    setUserData((prevData) => ({
                        ...prevData,
                        wallet: finlaamount,
                    }));

                }
                navigate("/user/my-bookings");
                window.location.reload();
                toast.success('Ticket book Successfully');
            } else {
                toast.error('Somthing Went Wrong');
            }

        }else{
            toast.error(addpayment_res.Response_Header.Error_Desc);
        }
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const options = country.map(option => ({
        value: option.name,
        label: option.name,
        currency: option.currency,
        name: option.name,
    }));
    const passengerTypeOptions = [
        { value: '0', label: 'Adult' },
        { value: '1', label: 'Child' },
        { value: '2', label: 'Infant' }
    ];
    const selectgender = [
        { value: '0', label: 'Male' },
        { value: '1', label: 'Female' },
    ];
    const selecttitle = [
        { value: 'MR', label: 'MR' },
        { value: 'MS', label: 'MS' },
        { value: 'MRS', label: 'MRS' },
        { value: 'MSTR', label: 'MSTR' },
        { value: 'MISS', label: 'MISS' },
    ];



    return (
        <div className="col-xl-8">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="booking-form">
                <form onSubmit={handleSubmit} id="flight-form" method="post" role="form" className="contact-form form-wizard">
                    <div className="form-wizard-header">
                        <ul className="nav list-unstyled form-wizard-steps clearfix">
                            <li className="nav-item activated">
                                <button type='button' className="nav-link">
                                    <span className="number">1</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Your Selection</h5>
                            </li>
                            <li className="nav-item active">
                                <button type='button' className="nav-link">
                                    <span className="number">2</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Your Details</h5>
                            </li>
                            <li className="nav-item">
                                <button type='button' className="nav-link">
                                    <span className="number">3</span><i className="fal fa-check"></i>
                                </button>
                                <h5 className="color-black">Final Step</h5>
                            </li>
                        </ul>
                    </div>

                    <div className="wizard-content overflow-visible mb-24">
                        <fieldset id="step-2" className="tab-pane show wizard-fieldset p-0">
                            <div className="detail-form mb-32">
                                {/* <Link to="/flight-listing" className="color-primary h6 mb-30">
                                    <i className="fal fa-chevron-left"></i>&nbsp;&nbsp;Go to Back
                                </Link> */}
                                <h4 className="black p-0 mb-30">Enter Your Details</h4>
                                {passengers.map((passenger, index) => (
                                    <div key={index} className="passenger-section">
                                        <h5>Passenger {index + 1}</h5><br />
                                        <div className="col-12">
                                            <div className="row">
                                                {/* Passenger Type */}
                                                <div className="col-sm-6 mb-12 sitdrpdwn">
                                                    <div className="gender-select">
                                                        <label htmlFor="passenger_type" className="h6 color-medium-gray mb-1">Passenger Type</label>
                                                        <Select
                                                            options={passengerTypeOptions}
                                                            name="passenger_type"
                                                            id="passenger_type"
                                                            value={passengerTypeOptions.find(option => option.value === passenger.passenger_type)}
                                                            onChange={(selectedOption) => handleInputChange({ ...selectedOption, name: "passenger_type" }, index)}
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Passenger Type"
                                                            isSearchable
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* Gender */}
                                                <div className="col-sm-6 mb-12 sitdrpdwn">
                                                    <div className="gender-select">
                                                        <label htmlFor="gender" className="h6 color-medium-gray mb-1">Select Gender</label>
                                                        <Select
                                                            options={selectgender}
                                                            name="gender"
                                                            id="gender"
                                                            value={selectgender.find(option => option.value === passenger.gender)}
                                                            onChange={(selectedOption) => handleInputChange({ ...selectedOption, name: "gender" }, index)}
                                                            classNamePrefix="react-select"
                                                            placeholder="Select Gender"
                                                            isSearchable
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* Title */}
                                                <div className="col-sm-2 mb-12 sitdrpdwn">
                                                    <div className="gender-select">
                                                        <label htmlFor="title" className="h6 color-medium-gray mb-1">Select Title</label>
                                                        <Select
                                                            options={selecttitle}
                                                            name="title"
                                                            id="title"
                                                            value={selecttitle.find(option => option.value === passenger.title)}
                                                            onChange={(selectedOption) => handleInputChange({ ...selectedOption, name: "title" }, index)}
                                                            classNamePrefix="react-select"
                                                            placeholder="Title"
                                                            isSearchable
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* First Name */}
                                                <div className="col-sm-4 mb-12 pe-1">
                                                    <label htmlFor="firstName" className="h6 color-medium-gray mb-1">First Name</label>
                                                    <input
                                                        type="text"
                                                        name="firstName"
                                                        value={passenger.firstName}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="First Name"
                                                        className="form-control wizard-required"
                                                        required
                                                    />
                                                </div>

                                                {/* Last Name */}
                                                <div className="col-sm-6 mb-12">
                                                    <label htmlFor="lastName" className="h6 color-medium-gray mb-1">Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        value={passenger.lastName}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="Last Name"
                                                        className="form-control wizard-required"
                                                        required
                                                    />
                                                </div>

                                                {/* Email */}
                                                <div className="col-sm-6 mb-12">
                                                    <label htmlFor="email" className="h6 color-medium-gray mb-1">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control wizard-required"
                                                        id="email"
                                                        name="email"
                                                        value={passenger.email}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="Email"
                                                        required
                                                    />
                                                </div>

                                                {/* Nationality */}
                                                <div className="col-sm-6 mb-12 sitdrpdwn">
                                                    <label htmlFor="nationality" className="h6 color-medium-gray mb-1">Select Nationality</label>
                                                    <Select
                                                        options={options}
                                                        name="nationality"
                                                        id="nationality"
                                                        value={options.find(option => option.value === passenger.nationality)}
                                                        onChange={(selectedOption) => handleInputChange({ ...selectedOption, name: "nationality" }, index)}
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Nationality"
                                                        isSearchable
                                                        required
                                                    />
                                                </div>

                                                {/* Passenger Mobile Number */}
                                                <div className="col-sm-6 mb-12">
                                                    <label htmlFor="Passenger_Mobile" className="h6 color-medium-gray mb-1">Passenger Mobile Number</label>
                                                    <input
                                                        type="tel"
                                                        className="form-control wizard-required"
                                                        id="Passenger_Mobile"
                                                        name="Passenger_Mobile"
                                                        value={passenger.Passenger_Mobile}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="Passenger Mobile Number"
                                                        required
                                                    />
                                                </div>

                                                {/* Date of Birth */}
                                                <div className="col-sm-6 mb-12">
                                                    <div className="input-date-picker">
                                                        <label htmlFor="dateOfBirth" className="h6 color-medium-gray mb-1">Date of Birth</label>
                                                        <DatePicker
                                                            selected={passenger.dateOfBirth}
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            onChange={(date) => handleInputChange({ target: { name: "dateOfBirth", value: format(new Date(date), 'MM/dd/yyyy') } }, index)}
                                                            dateFormat="MM/dd/yyyy"
                                                            className="sel-input date_from form-control wizard-required"
                                                            placeholderText="Date of Birth"
                                                            required
                                                            showMonthDropdown={true} // Disable month dropdown
                                                            showYearDropdown={true} // Disable year dropdown
                                                        />
                                                    </div>
                                                </div>

                                                {/* Passport Number */}
                                                <div className="col-sm-6 mb-12">
                                                    <label htmlFor="passport_Number" className="h6 color-medium-gray mb-1">Passport Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control wizard-required"
                                                        id="passport_Number"
                                                        name="passport_Number"
                                                        value={passenger.passport_Number}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="Passport Number"
                                                        required
                                                    />
                                                </div>

                                                {/* Passport Issuing Country */}
                                                <div className="col-sm-6 mb-12 sitdrpdwn">
                                                    <label htmlFor="Passport_Issuing_Country" className="h6 color-medium-gray mb-1">Select Passport Issuing Country</label>
                                                    <Select
                                                        options={options}
                                                        name="Passport_Issuing_Country"
                                                        id="Passport_Issuing_Country"
                                                        value={options.find(option => option.value === passenger.Passport_Issuing_Country)}
                                                        onChange={(selectedOption) => handleInputChange({ ...selectedOption, name: "Passport_Issuing_Country" }, index)}
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Passport Issuing Country"
                                                        isSearchable
                                                        required
                                                    />
                                                </div>

                                                {/* <div className="col-sm-6 mb-12">
                                                    <div className="input-date-picker">
                                                        <label htmlFor="Passport_Expiry" className="h6 color-medium-gray mb-1">
                                                            Passport Expiry
                                                        </label>
                                                        <DatePicker
                                                            value={passenger.Passport_Expiry}
                                                            id="Passport_Expiry"
                                                            name="Passport_Expiry"
                                                            onChange={(date) => handleInputChange({ target: { name: "Passport_Expiry", value: date } }, index)}
                                                            placeholderText="Passport Expiry"
                                                            dateFormat="MM/dd/yyyy"
                                                            className="sel-input date_from form-control wizard-required"
                                                            style={{
                                                                width: "100%",
                                                                padding: "10px",
                                                                cursor: "pointer",
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div> */}

                                                {/* Passport Expiry */}
                                                <div className="col-sm-6 mb-12">
                                                    <div className="input-date-picker">
                                                        <label htmlFor="Passport_Expiry" className="h6 color-medium-gray mb-1">Passport Expiry</label>
                                                        <DatePicker
                                                            selected={passenger.Passport_Expiry}
                                                            id="Passport_Expiry"
                                                            name="Passport_Expiry"
                                                            onChange={(date) => handleInputChange({ target: { name: "Passport_Expiry", value: format(new Date(date), 'MM/dd/yyyy') } }, index)}
                                                            dateFormat="MM/dd/yyyy"
                                                            className="sel-input date_from form-control wizard-required"
                                                            placeholderText="Passport Expiry"
                                                            style={{
                                                                width: "100%",
                                                                padding: "10px",
                                                                cursor: "pointer",
                                                            }}
                                                            required
                                                            showMonthDropdown={true} // Disable month dropdown
                                                            showYearDropdown={true} // Disable year dropdown
                                                        />
                                                    </div>
                                                </div>
                                                {/* PAN Card Number */}
                                                <div className="col-sm-6 mb-12">
                                                    <label htmlFor="pancard_Number" className="h6 color-medium-gray mb-1">PAN Card Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control wizard-required"
                                                        id="pancard_Number"
                                                        name="pancard_Number"
                                                        value={passenger.pancard_Number}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        placeholder="PAN Card Number"
                                                        required
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <button type="button" onClick={addPassenger} className="btn btn-primary btn-sm mb-3">
                                    Add Another Passenger
                                </button> */}
                            </div>
                        </fieldset>
                        <div className="mb-2">
                            <input
                                type="checkbox"
                                id="confirmBooking"
                                checked={isConfirmed}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="confirmBooking" className="ms-2">
                                I confirm that I want to proceed with the booking
                            </label>
                        </div>

                        <div className="row mb-4">
                            <div className="col-sm-12 mb-3">
                                <div className="final_step">
                                    <div className="radio-group-sit">
                                        <h5 className='mb-2'>Choose Payment Method</h5>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-sm-9 mb-3">
                                                <div className="radio-container">
                                                    <label className={paymentMethod === "razorpay" ? "active" : ""}>
                                                        <input
                                                            type="radio"
                                                            name="payment"
                                                            value="razorpay"
                                                            checked={paymentMethod === 'razorpay'}
                                                            onChange={handlePaymentChange}
                                                        />
                                                        <SiRazorpay />
                                                        <p className="textrr">Razor Pay</p>
                                                    </label>

                                                    <label className={paymentMethod === "wallet" ? "active" : ""}>
                                                        <input
                                                            type="radio"
                                                            name="payment"
                                                            value="wallet"
                                                            checked={paymentMethod === "wallet"}
                                                            onChange={handlePaymentChange}
                                                        />
                                                        <CiWallet />
                                                        <p className="textrr">Wallet</p>
                                                    </label>
                                                </div>
                                            </div>





                                            {/* <div className="col-sm-3 mb-3">
                                                {Progressing ?
                                                    <Progress />
                                                    :
                                                    <div className='col-12 float-end'>
                                                        <button type="submit" className="form-wizard-next-btn cus-btn cus-btn-strng w-100">
                                                            Book Now
                                                        </button>
                                                    </div>
                                                }
                                            </div> */}
                                            <div className="col-sm-3 mb-3">
                                                {Progressing ? (
                                                    <Progress />
                                                ) : (
                                                    <>

                                                        <div className="col-12 float-end">
                                                            <button
                                                                type="submit"
                                                                className="form-wizard-next-btn cus-btn cus-btn-strng w-100"

                                                            >
                                                                Book Now
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>





                    </div>
                </form>
            </div>
        </div>
    );
};

export default FlightBookingForm;
