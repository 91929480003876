import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../header/header.css';
import Footer from '../footer/footer';
import PreLoader from '../../component/preloader/preloader';
import BackToTop from '../backtootop/backtootop';
import WhatsappSupport from '../whatsapp-support/whatsapp-support';
import HeaderRightIcon from '../header/header-right';
import ProfileDropdown from '../nav-profile/nav-profile';
import logo from "../../assets/images/logo.png";
import { Modal } from 'react-bootstrap';
import { post as HelperPost,get } from "../../API/apiHelper";
import { wallet_add,siteconfig } from "../../API/endpoints";
import { razarpaypayment } from "../../API/utils";


const Logo = () => (
    <div className="main-menu__logo">
        <Link to="/">
            <img src={logo} alt="FlyNow" />
        </Link>
    </div>
);


const WalletButtons = ({ data, toggleModal, showModal, onSubmit, currency_code }) => (
    <div>
        <button className="wallet-hbtn" onClick={toggleModal}>
            <div>
                <label className="font-medium hover:cursor-pointer">
                    <div className="d-flex align-items-center gap-2">
                        <svg aria-label="An image of a closed passport" fill="none" viewBox="0 0 23 20" className="w-5 h-5 mr-3">
                            <path d="M21 11.2857V7.4C21 5.15979 21 4.03968 20.564 3.18404C20.1805 2.43139 19.5686 1.81947 18.816 1.43597C17.9603 1 16.8402 1 14.6 1H7.4C5.15979 1 4.03968 1 3.18404 1.43597C2.43139 1.81947 1.81947 2.43139 1.43597 3.18404C1 4.03968 1 5.15979 1 7.4V12.6C1 14.8402 1 15.9603 1.43597 16.816C1.81947 17.5686 2.43139 18.1805 3.18404 18.564C4.03968 19 5.15979 19 7.4 19H13M1.5 6H20.5M5 10H8M16 17H22M22 17L20 15M22 17L20 19" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </svg>
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currency_code, minimumFractionDigits: 2 }).format(data)}
                    </div>
                </label>
            </div>
        </button>

        <Modal show={showModal} onHide={toggleModal} size="M">
            <Modal.Header closeButton>
                <Modal.Title>Recharge Wallet</Modal.Title>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <label htmlFor="amount">Recharge Amount <span >*</span> </label>
                    <input className='form-control' name='amount' type="number" placeholder='Enter amount' required /><br />

                    <label htmlFor="description">Description</label>
                    <input className='form-control' name='description' type="text" placeholder='Enter description' value="wallet recharge" />

                </Modal.Body>
                <Modal.Footer>
                    <button type='Submit' className="btn btn-success">
                        Recharge Now
                    </button>
                    <button className="btn btn-secondary" onClick={toggleModal}>
                        Close
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
);

const NavMenu = ({ data }) => (
    <ul className="main-menu__list">
        <li><NavLink exact to="/" activeClassName="active">Home</NavLink></li>
        <li><NavLink to={data ? "/flight-listing" : "/login"} activeClassName="active">Flight Listing</NavLink></li>
        <li><NavLink to={data ? "/visa" : "/login"} activeClassName="active">Visa</NavLink></li>
        <li><NavLink to={data ? "/oktb" : "/login"} activeClassName="active">OTB</NavLink></li>
        <li><NavLink to="/about-us" activeClassName="active">About</NavLink></li>
        <li><NavLink to="/Contact-us" activeClassName="active">Contact Us</NavLink></li>
    </ul>
);

function Header({setting, pagename, page }) {

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };
    const closePopup = () => {
        setIsPopupVisible(false);
    };

    const [isSNavbarVisible, setIsSNavbarVisible] = useState(false);
    const toggleSNavbar = () => {
        setIsSNavbarVisible(!isSNavbarVisible);
    };
    const closeSNavbar = () => {
        setIsSNavbarVisible(false);
    };

    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
       
    }, []);

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target); // Create a FormData object from the form
        const amount = formData.get('amount'); // Get the amount
        const description = formData.get('description'); // Get the description
        const ordre_id = Math.floor(10000000 + Math.random() * 90000000);
        setShowModal(toggleModal)
        // initiateRazorpayPayment(ordre_id, amount, description);
        razarpaypayment(ordre_id, amount,description, "", async (response) => {
            if (response.razorpay_payment_id && response.razorpay_payment_id != null) {
                const formData = {
                    user_id: userData.id,
                    order_id: ordre_id,
                    transaction_type: description,
                    amount: amount,
                    payment_getway: 'Rezorpay',
                    details: JSON.stringify(response),
                    type: '1',
                    status: 'Success',
                }
                const apiresponse = await HelperPost(wallet_add, formData, true);
                const data = await apiresponse.json();
                if (data.status == false) {
                    console.error('Error:', data.message);
                } else {
                    let userData = sessionStorage.getItem('userData');
                    userData = userData ? JSON.parse(userData) : {};
                    userData.model.wallet = data.data.wallet;
                    sessionStorage.setItem('userData', JSON.stringify(userData));
                    setUserData((prevData) => ({
                        ...prevData,
                        wallet: data.data.wallet,
                    }));
                }
            }
        });
    }

 

    return (
        <>
            <PreLoader />
            <header>
                <nav className="main-menu">
                    <div className="container">
                        <div className="main-menu__block">
                            <div className="main-menu__left">
                                <Logo />
                                <div className="main-menu__nav">
                                    <NavMenu data={userData} />
                                </div>
                            </div>
                            <div className="main-menu__right">
                                <div className="main-menu-signup__login d-xl-flex d-none">
                                    {userData && <WalletButtons data={userData.wallet} toggleModal={toggleModal} showModal={showModal} onSubmit={handleSubmit} currency_code={userData.currency_code} />}
                                    <ProfileDropdown />
                                </div>
                                <button className="main-menu__toggler mobile-nav__toggler" onClick={toggleSNavbar} >
                                    <i className="fa fa-bars"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {page}
            <WhatsappSupport />
            <BackToTop />
            {setting && <Footer data={setting} />}
        </>
    );
}

export default Header;
