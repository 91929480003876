import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import route_plane from "../../../../assets/images/icon/route-plan.png";
import logo from "../../../../assets/images/logo.png";
import Airlogo from '../../../../widget/air_logo';
import html2pdf from 'html2pdf.js';
import { QRCodeCanvas } from 'qrcode.react';
import { SERVER_URL, booking_cancle } from "../../../../API/endpoints";
import Ticket_Details from '../../../../widget/ticket_details';
import { post } from '../../../../API/apiHelper'; // Assuming you have an API helper for making requests
import { ToastContainer, toast } from "react-toastify";

const BookingWidget = ({ data }) => {

    let bookingdata = null;
    if (data.Ticket_Details) {
        bookingdata = JSON.parse(data.Ticket_Details);
    }

    const [cancelReason, setCancelReason] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false); // New state for cancel confirmation modal

    const toggleModal = () => setShowModal(!showModal);
    const toggleCancelModal = () => setShowCancelModal(!showCancelModal); // Toggle cancel modal visibility

    const QrCodeComponent = ({ value }) => {
        const ref_no = btoa(value.Booking_RefNo);
        const currentUrl = window.location.origin;
        const dataToEncode = `${currentUrl}/Download_ticket/${ref_no}`;
        return (
            <div>
                <QRCodeCanvas value={dataToEncode} size={100} level="M" />
                <p style={{ marginTop: '10px' }}>Scan for Ticket</p>
            </div>
        );
    };

    // Function to handle booking cancellation
    const handleCancelBooking = async () => {
        try {

            if (!cancelReason.trim()) {
                toast.error('Please provide a reason for cancellation.');
                return;
            }

            const response = await post(booking_cancle, { Booking_RefNo: bookingdata.Booking_RefNo, description: cancelReason }, true); // Replace with actual endpoint
            if (response.ok) {
                toast.success('Booking cancelled successfully.');
                setShowCancelModal(false); // Close the cancel modal
            } else {
                toast.error('Failed to cancel booking.');
            }
        } catch (error) {
            console.error('Cancellation error:', error);
            alert('An error occurred during cancellation.');
        }
    };


    return (
        bookingdata == null ? <></> :
            <div className="border mb-4 card">
                <div>
                    <span style={{ backgroundColor: 'blanchedalmond' }}>
                        {data.status}
                    </span>

                </div>
                <div className="border-bottom d-md-flex justify-content-md-between align-items-center card-header">
                    <div className="d-flex align-items-center">
                        <div className="icon-lg bg-light rounded-circle flex-shrink-0">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                {bookingdata ? (
                                    <path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"></path>
                                ) : (
                                    <path d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91z"></path>
                                )}
                            </svg>
                        </div>
                        <div>

                        </div>
                        <div className="ms-2">
                            <h6 className="card-title mb-0">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Airline_Name}({bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Airline_Code})</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Booking ID: {bookingdata.Booking_RefNo}</li>
                            </ul>
                        </div>

                        <div className="flight-detail ms-2">
                            <div className="d-inline-flex align-items-center gap-8">
                                <span>{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Origin}</span>
                                <div className="from-to text-center">
                                    <h5 className="dark-gray">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Duration}</h5>
                                    <img className='f_icon_list' src={route_plane} alt="route-plan" />
                                    <h6 className="color-black">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Stop_Over} Stop</h6>
                                </div>
                                <span>{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Destination}</span>
                            </div>
                        </div>

                    </div>
                    <div className="mt-2 mt-md-0">
                        <button type="button" className="mb-0 btn btn-primary-soft" onClick={toggleModal}>Show ticket</button>


                        {data.status != 'cancelled' && (
                            <>
                                <button type="button" className="mb-0 ms-2 btn btn-danger" onClick={toggleCancelModal}>Cancel</button>
                            </>
                        )}

                    </div>
                </div>

                {/* Show Ticket Modal */}
                <Modal show={showModal} onHide={toggleModal} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Ticket Details</Modal.Title>
                    </Modal.Header>
                    <Ticket_Details reference_id={bookingdata.Booking_RefNo} />
                </Modal>

                {/* Cancel Confirmation Modal */}
                <Modal show={showCancelModal} onHide={toggleCancelModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to cancel this booking?</p><br />
                        <div className="mb-3">
                            <label htmlFor="cancelReason" className="form-label">Cancellation Reason</label>
                            <textarea
                                id="cancelReason"
                                className="form-control"
                                placeholder="Please provide a reason for cancellation (optional)"
                                rows="2"
                                onChange={(e) => setCancelReason(e.target.value)} // Update the reason in state
                            ></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={toggleCancelModal}>Close</button>
                        <button type="button" className="btn btn-danger" onClick={handleCancelBooking}>Confirm Cancel</button>
                    </Modal.Footer>
                </Modal>

                <div className="card-body">
                    <div className="g-3 row">
                        {bookingdata ? (
                            <>
                                <div className="col-md-3 col-sm-6">
                                    <span>Departure time</span>
                                    <h6 className="mb-0">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Departure_DateTime}</h6>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <span>Arrival time</span>
                                    <h6 className="mb-0">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Arrival_DateTime}</h6>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-md-3 col-sm-6">
                                    <span>Pickup address</span>
                                    <h6 className="mb-0">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Origin}</h6>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <span>Drop address</span>
                                    <h6 className="mb-0">{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Destination}</h6>
                                </div>
                            </>
                        )}

                        <div className="col-md-3 col-sm-6">
                            <span>Passanger ({Number(bookingdata.Adult_Count) + Number(bookingdata.Child_Count) + Number(bookingdata.Infant_Count)})</span>
                            {bookingdata.AirPNRDetails[0].PAXTicketDetails.map((Passanger, index) => (
                                <h6 className="mb-0" key={index}>{Passanger.Title} {Passanger.First_Name} {Passanger.Last_Name}</h6>
                            ))}
                        </div>

                        <div className='col-md-3'>
                            <QrCodeComponent value={bookingdata} />
                        </div>



                    </div>
                </div>
            </div>
    );
};

export default BookingWidget;
