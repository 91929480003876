import React, { useState, useEffect } from 'react';
// import '../../pages/agent/wallet-history.css'
import html2pdf from 'html2pdf.js'; // Import the library
import { QRCodeCanvas } from 'qrcode.react';
import logo from "../assets/images/logo.png"
import Airlogo from '../widget/air_logo'
import { AIR_2_URL, AIR_REPRINT, third_party, siteconfig, airline_code, IMAGE_BASE_URL } from "../API/endpoints";
import { post, get } from "../API/airline";
import { post as HelperPost } from "../API/apiHelper";

import route_plane from "../assets/images/icon/route-plan.png";
import Progress from "../component/Loading";
import './ticket_details.css'


function Ticket_Details({ reference_id }) {

    const [setting, setSettings] = useState(null);
    const [airlinelogo, setAirlinelogo] = useState("");

    const [TicketRecord, setTicketRecord] = useState(null);
    useEffect(() => {
        const fetchTicketRecord = async () => {
            try {
                const rpayload = {
                    "Auth_Header": {
                        "UserId": "viviantravelsuat",
                        "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                        "IP_Address": "12333333",
                        "Request_Id": "5500833959053023879",
                        "IMEI_Number": "9536615000"
                    },
                    "Booking_RefNo": reference_id,
                    "Airline_PNR": ""
                };
                const aurl = await AIR_2_URL() + AIR_REPRINT;
                const resp = await post(third_party, JSON.stringify(rpayload), aurl);
                const trecord = await resp.json();
                setTicketRecord(trecord.data);

                console.log(trecord.data)

                fetchairlinedata(trecord.data.AirPNRDetails[0].Flights[0].Segments[0].Airline_Code);
            } catch (error) {
                console.error("Error fetching ticket record:", error);
            }
        };
        const fetchSettings = async () => {
            try {
                const res = await get(siteconfig, true);
                const response = await res.json();
                setSettings(response.data);
            } catch (error) {
                console.log(error)
            }
        };
        const fetchairlinedata = async (airlinecode) => {
            try {
                const res = await HelperPost(airline_code, { "code": airlinecode }, true);
                const response = await res.json();
                if (response.data != null) {
                    setAirlinelogo(response.data.logo || "");
                }
            } catch (error) {
                console.log(error)
            }
        };
        fetchSettings();
        fetchTicketRecord();
    }, [reference_id]);

    const getClassOfTravel = (classCode) => {
        switch (classCode) {
            case 0:
                return 'ECONOMY';
            case 1:
                return 'BUSINESS';
            case 2:
                return 'FIRST';
            case 3:
                return 'PREMIUM ECONOMY';
            default:
                return 'UNKNOWN CLASS'; // or return an empty string
        }
    };

    const getpaxtype = (code) => {
        switch (code) {
            case 0:
                return 'ADULT';
            case 1:
                return 'CHILD';
            case 2:
                return 'INFANT';
            default:
                return 'UNKNOWN'; // or return an empty string
        }
    };

    const formatDate = (dateString) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    };

    const downloadPDF = () => {
        const element = document.getElementById('ticket-details'); // Reference the modal content by its ID

        // Options for the pdf generation
        const options = {
            margin: 1,
            filename: 'Vivan_travels_' + TicketRecord.Booking_RefNo + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Generate PDF from the element
        html2pdf()
            .from(element)
            .set(options)
            .save();
    };

    const QrCodeComponent = ({ value }) => {
        return (
            <div>
                <QRCodeCanvas
                    value={value}
                    size={100}
                    level="M" // Error correction level (L, M, Q, H)
                />
                <p style={{ marginTop: '10px' }}>Scan for Ticket</p>
            </div>
        );
    };

    return (

        <>

            {TicketRecord ? (
                <div id="ticket-details" className="p-3 ticketdwnld" style={{ fontFamily: 'Arial, sans-serif' }}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                        <div className="">
                            <img src={logo} alt="Vivian Travels & Tourism" style={{ height: '50px' }} />
                        </div>
                        <div className="">
                            <p>{`${window.location.protocol}//${window.location.host}`}</p>
                            {setting?.support_email && <p>{setting.support_email}</p>}
                            {setting?.support_no && <p>{setting.support_no}</p>}
                        </div>
                    </div>

                    {TicketRecord.AirPNRDetails[0].Flights.map((Details, index) => (
                        <>
                            <div className="bg-dark text-white px-3 py-1 d-flex flex-wrap justify-content-between align-items-center">
                                <h5 className="mt-0">
                                    Your flight from {Details.Segments[0].Origin} → {Details.Segments[0].Destination}
                                </h5>
                                <h6 className="mt-0">*Please Verify Flight Times With The Airlines Prior To Departure</h6>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 border border-secondary p-3">
                                <div className="col-2 mb-3">
                                    {/* <Airlogo airCode={airlinelogo} /> */}
                                    <div>
                                        <img src={IMAGE_BASE_URL + airlinelogo} alt={`${Details.Segments[0].Airline_Code} logo`} width="80" style={{ height: '80px' }} />
                                    </div>
                                    <p>Airline code: {Details.Segments[0].Airline_Code}</p>
                                    <p>Airline PNR: {TicketRecord.AirPNRDetails[0].Airline_PNR}</p>
                                </div>
                                <div className="col-5 mb-3 text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="flex-fill mb-3 text-center">
                                            <p>{Details.Segments[0].Origin}</p>
                                            <p><strong>{formatDate(Details.Segments[0].Departure_DateTime)}</strong></p>
                                        </div>
                                        <div className="mb-3">
                                            <img className='f_icon_list' src={route_plane} alt="route-plan" />
                                        </div>
                                        <div className="flex-fill mb-3 text-center">
                                            <p>{Details.Segments[0].Destination}</p>
                                            <p><strong>{formatDate(Details.Segments[0].Arrival_DateTime)}</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 mb-3">
                                    <p>Stop Over: {Details.Segments[0].Stop_Over == null ? 'Non Stop' : Details.Segments[0].Stop_Over + ' Stop'}</p>
                                    <p>Duration: {Details.Segments[0].Duration}</p>
                                    <p>Class: {getClassOfTravel(TicketRecord.Class_of_Travel)}</p>
                                    <p>Check In Baggage: {Details.Fares[0].FareDetails[0].Free_Baggage.Check_In_Baggage}</p>
                                    <p>Hand Baggage: {Details.Fares[0].FareDetails[0].Free_Baggage.Hand_Baggage}</p>
                                    <p>Ticket Status: {TicketRecord.AirPNRDetails[0].Ticket_Status_Desc}</p>
                                </div>
                            </div>
                        </>
                    ))}



                    <div>
                        <div className="bg-dark text-white px-3 py-1"><h5 className="mt-0">Passenger Details</h5></div>
                        {/* <h5>Passenger Details</h5> */}
                        <table className="table table-bordered mb-4 mt-0">
                            <thead className="table-light">
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Passenger Name</th>
                                    <th>Passport No</th>
                                    <th>Type</th>
                                    <th>PNR</th>
                                    <th>Ticket Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TicketRecord.AirPNRDetails[0].PAXTicketDetails.map((details, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{details.Title} {details.First_Name} {details.Last_Name}</td>
                                        <td>{details.Passport_Number}</td>
                                        <td>{getpaxtype(details.Pax_type)}</td>
                                        <td>{TicketRecord.AirPNRDetails[0].CRS_PNR}</td>
                                        <td>{TicketRecord.Booking_RefNo}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className=''>
                        <h5>Important Information</h5>
                        <ul className="list-unstyled">
                            <li>This ticket is Non Refundable & Non Changeable.</li>
                            <li>International flights check-in opens 180 minutes before departure.</li>
                            <li>All Passengers/Infants must carry their Passport which has to be valid at least 6 months from the date of travel while Check-in.</li>
                            <li>Please contact airlines for Terminal Queries.</li>
                            <li>Charged fare is totally agreed between "BUYER & SELLER"; any issues related to fares thereafter will not be entertained.</li>
                        </ul>
                    </div>

                    <span>Booking Date Time: {TicketRecord.Booking_DateTime}</span>

                </div>


            ) : (
                <div>
                    <Progress />
                </div>)}
            <div className="d-flex justify-content-end align-items-center m-4">
                <button className="cus-btn" onClick={downloadPDF}>
                    Download
                </button>
            </div>
        </>


    );
}

export default Ticket_Details;